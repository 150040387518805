import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Facility } from './facilities.model';
import { map } from 'rxjs/operators';
import { AppState } from 'src/app/app.model';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import {
  SKELETON_ALL_FACILITY_CARDS,
  SKELETON_FACILITY_CARDS
} from 'src/app/test-summary/test-summary.config';
import { ShowSkeletonLoader, HideSkeletonLoader } from 'src/app/core/store/skeleton-store/skeleton.action';
@Injectable({
  providedIn: 'root'
})

export class FacilitiesService {
  public changeFacilityId = new BehaviorSubject(-1);
  constructor(private http: HttpClient, private store: Store<AppState>) {}

  public getAllFacility(CompanyId: string, facilityId: number) {
    const actionPaylod = {
      parentKey: SKELETON_ALL_FACILITY_CARDS,
      childKey: SKELETON_FACILITY_CARDS
    };
    this.store.dispatch(new ShowSkeletonLoader(actionPaylod));
    return this.http
      .get<Facility>(
        `${environment.apiBaseUrl}/companies/${CompanyId}/facilities/${facilityId}/carddetails`
      )
      .pipe(
        map(response => {
          this.store.dispatch(new HideSkeletonLoader(actionPaylod));
          return response;
        })
      );
  }
}