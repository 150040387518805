import { SkeletonActionType, SkeletonAction } from './skeleton.action';
import { SkeletonState } from './skeleton.model';

export function skeletonReducer(
  state: SkeletonState = new SkeletonState(),
  action: SkeletonAction
): SkeletonState {
  switch (action.type) {
    case SkeletonActionType.SHOW_SKELETON:
      const existingSkeletonStateForShow = state.skeletonData;
      existingSkeletonStateForShow[action.payload.parentKey][action.payload.childKey] = true;
      return {
        ...state,
        skeletonData: existingSkeletonStateForShow
      };
    case SkeletonActionType.HIDE_SKELETON:
      const existingSkeletonStateForHide = state.skeletonData;
      existingSkeletonStateForHide[action.payload.parentKey][action.payload.childKey] = false;
      return {
        ...state,
        skeletonData: existingSkeletonStateForHide
      };

    default:
      return state;
  }
}
