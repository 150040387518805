import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatTableDataSource, MatSort, MatPaginator, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { Observable, Subscription } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.model';
import {
  selectUsersList,
  searchedtUsersList,
  selectUpdatedUser
} from 'src/app/user-management/store/user-management/user-management.selectors';
import { AllRoles } from 'src/app/user-management/add-user/add-user.model';
import { Facility } from 'src/app/user-management/facilities/facilities.model';
import { selectSelectedFacility } from 'src/app/core/store/facility-store/facility.selectors';
import { selectedAllUsersData, selectTestStatusUsers } from 'src/app/test-summary/store/test-summary.selectors';
import { DatePickerDateFormat, DATE_PICKER_DATE_FORMATS } from '../../healpers/date-picker-date-format';
import { startWith, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import value from '*.md';
import { Router } from '@angular/router';
@Component({
  selector: 'cer-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: DatePickerDateFormat },
    { provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_DATE_FORMATS }
  ]
})
export class DynamicTableComponent implements OnInit, OnDestroy {
  @Input() dataSource;
  @Input() displayedColumns;
  @Input() columnNames;
  @Input() records;
  @Input() pages;
  @Input() size;
  @Input() noUserMessage;
  @Input() enableCompanySearch;
  @Input() paginationClick;
  @Input() showAdvanceSearchForCertusAdmin;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, null) paginator: MatPaginator;
  @Output() selectedUser = new EventEmitter();
  @Output() selectedWorkOrder = new EventEmitter();
  @Output() selectedPageChange = new EventEmitter();
  @Output() selectedSearchInput = new EventEmitter();
  @Output() sortUserFnameAz = new EventEmitter();
  @Output() sortUserFnameZa = new EventEmitter();
  @Output() sortUserLnameAz = new EventEmitter();
  @Output() sortUserLnameZa = new EventEmitter();
  @Output() sortEmpNoAz = new EventEmitter();
  @Output() sortEmpNoZa = new EventEmitter();
  @Output() removeFilterName = new EventEmitter();
  @Output() sortCompanyNameAz = new EventEmitter();
  @Output() sortCompanyNameZa = new EventEmitter();
  @Output() sortCompanyIdAz = new EventEmitter();
  @Output() sortCompanyIdZa = new EventEmitter();
  @Output() sortfacilityNameAz = new EventEmitter();
  @Output() sortfacilityNameZa = new EventEmitter();
  @Output() sortTestStartDateAz = new EventEmitter();
  @Output() sortTestStartDateZa = new EventEmitter();
  @Output() sortTestEndDateAz = new EventEmitter();
  @Output() sortTestEndDateZa = new EventEmitter();
  @Output() clickedDeleted = new EventEmitter();
  @Output() sortCreatedOnDateZa = new EventEmitter();
  @Output() sortCreatedOnDateAz = new EventEmitter();
  @Output() freeTextValue = new EventEmitter();
  @Output() emitFreeTextFormValue = new EventEmitter();
  @Output() clickedOnBoardStatus = new EventEmitter();
  @Output() clickHere = new EventEmitter();

  public companyID: string;
  public facilityID: number;
  public searchForm: FormGroup;
  public searchWorkOrderForm: FormGroup;
  public searchCompanyForm: FormGroup;
  public showSearch: boolean;
  public showHideTooltip: boolean = null;
  public showSort: boolean;
  public userId: string;
  public showFnameClose: boolean;
  public showClose: boolean;
  public showAsc: boolean;
  public showCompanyAsc: boolean;
  public showCompanyDesc: boolean;
  public showCompanyIdAsc: boolean;
  public showCompanyIdDesc: boolean;
  public showFacilityAsc: boolean;
  public showFacilityDesc: boolean;
  public showStartDateAsc: boolean;
  public showStartDateDesc: boolean;
  public showEndDateAsc: boolean;
  public showEndDateDesc: boolean;
  public showCreatedOnAsc: boolean;
  public showCreatedOnDesc: boolean;
  public keyPressedValue: string;
  public freeTextFormValue: string;
  public timeout = null;
  public showChipsList = [];
  public chipObject;
  public showDesc: boolean;
  public searchFlag = false;
  public testName;
  public selectedFacilitySub$: Subscription;
  public selectedAllUserDataSub$: Subscription;
  public selectAdvanceFlagSub$: Subscription;
  public selectedtTestStatusDataSub$: Subscription;
  public usersDataSub$: Subscription;
  public selectedUserUpdateSub$: Subscription;
  public usersData$: Observable<any> = this.store.select(selectUsersList);
  public searchedUserData$: Observable<AllRoles[]> = this.store.select(
    searchedtUsersList);
  public selectedFacility$: Observable<Facility> = this.store.select(
    selectSelectedFacility);
  public selectedAllUserData$: Observable<any> = this.store.select(selectedAllUsersData);
  public selectedtTestStatusDeta$: Observable<any> = this.store.select(selectTestStatusUsers);
  public selectedUserUpdate$: Observable<Facility> = this.store.select(selectUpdatedUser);
  constructor(
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private router: Router,
    private datePipe: DatePipe,
    private translate: TranslateService
  ) { }
  public options: string[];
  public companyOption: string[];
  filteredOptions: Observable<string[]>;
  filteredCompanyStatusOptions: Observable<string[]>;

  public allStatus = [
    {
      id: 1,
      value: 'Onboarded',
      name: 'Onboarded'
    },
    {
      id: 2,
      value: 'OnHold',
      name: 'OnHold'
    },
    {
      id: 3,
      value: 'Inactive',
      name: 'Inactive'
    }
  ];

  /**
   * Used to create search form and to get local storage data
   */
  async ngOnInit() {
    await new Promise(res => {
      this.translate.get('DYNAMIC').subscribe(translations => {
        res();
      });
    });
    this.options = [
      this.translate.instant('DYNAMIC.CREATED'),
      this.translate.instant('DYNAMIC.IN_PROGRESS'),
      this.translate.instant('DYNAMIC.COMPLETED'),
      this.translate.instant('DYNAMIC.DELETED')
    ];
    this.companyOption = [
      this.translate.instant('DYNAMIC.ON_BOARD'),
      this.translate.instant('DYNAMIC.PENDING'),
      this.translate.instant('DYNAMIC.ON_HOLD'),
      this.translate.instant('DYNAMIC.IN_ACTIVE')
    ];
    this.selectedFacilitySub$ = this.selectedFacility$.subscribe(data => {
      if (data) {
        this.facilityID = data.facilityId;
        this.paginator.pageIndex = 0;
        this.keyPressedValue = '';
        this.showChipsList = [];
      }
    });
    if (this.selectedtTestStatusDeta$) {
      this.selectedtTestStatusDataSub$ = this.selectedtTestStatusDeta$.subscribe(response => {
        if (response) {
          if (this.paginationClick === false) {
            this.paginator.pageIndex = 0;
          }
        }
      });
    }

    if (this.selectedUserUpdate$) {
      this.selectedUserUpdateSub$ = this.selectedUserUpdate$.subscribe(res => {
        if (res) {
          this.paginator.pageIndex = 0;
        }
      });
    }

    if (jwt_decode(localStorage.getItem('accessToken')).permission[0] === this.translate.instant('DYNAMIC.ADMIN')) {
      this.searchFlag = true;
    }
    this.showAsc = true;
    this.showCompanyAsc = true;
    this.showCompanyIdAsc = true;
    this.showFacilityAsc = true;
    this.showStartDateAsc = true;
    this.showEndDateAsc = true;
    this.showCreatedOnAsc = true;
    this.showSearch = false;
    this.showSort = false;
    this.showFnameClose = false;
    this.showClose = false;
    const tokenData = localStorage.getItem('accessToken');
    const decodedInfo = jwt_decode(tokenData);
    this.companyID = decodedInfo.companyId;
    this.userId = decodedInfo.id;
    this.dataSource = new MatTableDataSource<any>(this.dataSource);
    this.dataSource.sort = this.sort;
    this.searchForm = this.formBuilder.group({
      searchFname: [''],
      searchLname: [''],
      searchEmployeeId: [''],
      searchDepartment: [''],
      searchRole: ['']
    });
    this.searchWorkOrderForm = this.formBuilder.group({
      searchCompanyname: [''],
      searchFacilityname: [''],
      searchworkOrderId: [''],
      searchTest: [''],
      searchStartDate: [''],
      searchEndDate: [''],
      searchCreatedOnDate: [''],
      searchStatus: ['']
    });
    this.searchCompanyForm = this.formBuilder.group({
      searchCompanyID: [''],
      searchCompanyName: [''],
      searchCompanyCity: [''],
      searchCompanyState: [''],
      searchCompanyZipcode: [''],
      searchCompanyCreatedOnDate: [''],
      searchCompanyStatus: ['']
    });
    this.filteredOptions = this.searchWorkOrderForm.get('searchStatus').valueChanges
      .pipe(
        startWith(''),
        map(values => this._filter(values))
      );
    this.filteredCompanyStatusOptions = this.searchCompanyForm.get('searchCompanyStatus').valueChanges
      .pipe(
        startWith(''),
        map(data => this.filterCompanyStatus(data))
      );
    if (this.selectedAllUserData$) {
      this.selectedAllUserDataSub$ = this.selectedAllUserData$.subscribe(response => {
        if (response) {
          this.paginator.pageIndex = 0;
        }
      });
    }
  }

  public _filter(val: string): string[] {
    const filterValue = val;
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
  public filterCompanyStatus(data: string): string[] {
    const filterData = data;
    return this.companyOption.filter(options => options.toLowerCase().includes(filterData));
  }
  hasPropTest(testObject, testType) {
    const found = testObject.find(element => element.testType === testType);
    if (found !== undefined) {
      return true;
    } else { return false; }
  }

  deleteUser(userObject) {
    this.clickedDeleted.emit(userObject);
  }

  /**
   * Used to search the table data as per the input passed
   */
  onSearchClick() {
    this.showChipsList = [];
    const inputFirstName = this.searchForm.get('searchFname').value ? this.searchForm.get('searchFname').value.trim() : 'null';
    const inputLastName = this.searchForm.get('searchLname').value ? this.searchForm.get('searchLname').value.trim() : 'null';
    const inputEmployeeId = this.searchForm.get('searchEmployeeId').value ? this.searchForm.get('searchEmployeeId').value.trim() : 'null';
    const inputDepartment = this.searchForm.get('searchDepartment').value ? this.searchForm.get('searchDepartment').value.trim() : 'null';
    const inputRole = this.searchForm.get('searchRole').value ? this.searchForm.get('searchRole').value.trim() : 'null';
    const textInput = {
      FirstName: inputFirstName,
      LastName: inputLastName,
      EmployeeId: inputEmployeeId,
      Department: inputDepartment,
      Role: inputRole
    };
    for (const val in textInput) {
      if (textInput[val] !== 'null') {
        this.showChipsList.push({ key: val, value: textInput[val] });
      }
    }
    this.selectedSearchInput.emit(textInput);
    this.showSearch = false;
    this.searchForm.reset();
  }

  /**
   * used to search the workOrder data from work order management when user click to the  search button
   */
  onWorkOrderSearchClick() {
    this.showChipsList = [];
    const inputCompanyName = this.searchWorkOrderForm.get('searchCompanyname')
      .value ? this.searchWorkOrderForm.get('searchCompanyname').value.trim() : 'null';
    const inputFacilityName = this.searchWorkOrderForm.get('searchFacilityname')
      .value ? this.searchWorkOrderForm.get('searchFacilityname').value.trim() : 'null';
    const inputWorkOrderId = this.searchWorkOrderForm.get('searchworkOrderId')
      .value ? this.searchWorkOrderForm.get('searchworkOrderId').value.trim() : 'null';
    const inputTest = this.searchWorkOrderForm.get('searchTest')
      .value ? this.searchWorkOrderForm.get('searchTest').value.trim() : 'null';
    const inputStartDate = this.searchWorkOrderForm.get('searchStartDate')
      .value ? this.datePipe.transform(new Date(this.searchWorkOrderForm.get('searchStartDate').value), 'MM/dd/yyyy') : 'null';
    const inputEndDate = this.searchWorkOrderForm.get('searchEndDate')
      .value ? this.datePipe.transform(new Date(this.searchWorkOrderForm.get('searchEndDate').value), 'MM/dd/yyyy') : 'null';
    const inputCreatedOnDate = this.searchWorkOrderForm.get('searchCreatedOnDate')
      .value ? this.datePipe.transform(new Date(this.searchWorkOrderForm.get('searchCreatedOnDate').value), 'MM/dd/yyyy') : 'null';
    const inputstatus = this.searchWorkOrderForm.get('searchStatus').value ? this.searchWorkOrderForm.get('searchStatus').value : 'null';
    const textWorkOrderSearchInput = {
      CompanyName: inputCompanyName,
      FacilityName: inputFacilityName,
      WorkorderId: inputWorkOrderId,
      Test: inputTest,
      StartDate: inputStartDate,
      EndDate: inputEndDate,
      CreatedOn: inputCreatedOnDate,
      status: inputstatus
    };
    for (const val in textWorkOrderSearchInput) {
      if (textWorkOrderSearchInput[val] !== 'null') {
        this.showChipsList.push({ key: val, value: textWorkOrderSearchInput[val] });
      }
    }
    this.selectedSearchInput.emit(textWorkOrderSearchInput);
    this.showSearch = false;
    this.searchWorkOrderForm.reset();
  }

  /**
   * used to search the Company data from company managemenet grid when user click to the  search button
   */
  onCompanySearchClick() {
    this.showChipsList = [];
    const inputCompanyID = this.searchCompanyForm.get('searchCompanyID').value ?
      this.searchCompanyForm.get('searchCompanyID').value.trim() : 'null';
    const inputCompanyNm = this.searchCompanyForm.get('searchCompanyName').value ?
      this.searchCompanyForm.get('searchCompanyName').value.trim() : 'null';
    const inputCompanyCity = this.searchCompanyForm.get('searchCompanyCity').value ?
      this.searchCompanyForm.get('searchCompanyCity').value.trim() : 'null';
    const inputCompanyState = this.searchCompanyForm.get('searchCompanyState').value ?
      this.searchCompanyForm.get('searchCompanyState').value.trim() : 'null';
    const inputCompanyZipcode = this.searchCompanyForm.get('searchCompanyZipcode').value ?
      this.searchCompanyForm.get('searchCompanyZipcode').value.trim() : 'null';
    const inputCompanyCreatedOnDate = this.searchCompanyForm.get('searchCompanyCreatedOnDate').value ?
      this.datePipe.transform(new Date(this.searchCompanyForm.get('searchCompanyCreatedOnDate').value), 'MM/dd/yyyy') : 'null';
    const inputCompanyStatus = this.searchCompanyForm.get('searchCompanyStatus').value ?
      this.searchCompanyForm.get('searchCompanyStatus').value : 'null';

    const textCompanySearchInput = {
      CompanyID: inputCompanyID,
      CompanyName: inputCompanyNm,
      City: inputCompanyCity,
      State: inputCompanyState,
      Zipcode: inputCompanyZipcode,
      CreatedOnDate: inputCompanyCreatedOnDate,
      Status: inputCompanyStatus
    };
    for (const val in textCompanySearchInput) {
      if (textCompanySearchInput[val] !== 'null') {
        this.showChipsList.push({ key: val, value: textCompanySearchInput[val] });
      }
    }
    this.selectedSearchInput.emit(textCompanySearchInput);
    this.showSearch = false;
    this.searchCompanyForm.reset();
  }

  /**
   * Used to display table data after filter remove
   */
  removeFilter(key, index) {
    this.removeFilterName.emit(key);
    this.showChipsList.splice(index, 1);
  }

  getWorkOrderStatus(user) {
    this.selectedWorkOrder.emit(user);
  }

  /**
   * Used to show and hide search panel on condition
   */
  showSearchField() {
    this.showSearch = !this.showSearch;
  }

  /**
   * Used to show and hide sort panel on condition
   */
  showSortOption() {
    this.showSort = !this.showSort;
  }

  onClickedOutside(event: Event) {
    this.showSearch = false;
    this.showHideTooltip = false;
  }

  onClickedOutsideSort(event: Event) {
    this.showSort = false;
  }

  /**
   * clear manage user search
   */
  cancelSearch() {
    this.searchForm.reset();
  }

  /**
   * clear workorder search
   */
  clearWorkOrderSearch() {
    this.searchWorkOrderForm.reset();
  }

  /**
   * clear company search
   */
  clearCompanySearch() {
    this.searchCompanyForm.reset();
  }

  /**
   * Used to sort table data based on first name in ascending  order
   */
  sortFirstNameAZ() {
    this.sortUserFnameAz.emit();
    this.showSort = false;
  }

  /**
   * Used to sort table data based on first name in descending  order
   */
  sortFirstNameZA() {
    this.sortUserFnameZa.emit();
    this.showSort = false;
  }

  /**
   * Used to sort table data based on last name in ascending  order
   */
  sortLastNameAZ() {
    this.sortUserLnameAz.emit();
    this.showSort = false;
  }

  /**
   * Used to sort table data based on last name in descending  order
   */
  sortLastNameZA() {
    this.sortUserLnameZa.emit();
    this.showSort = false;
  }

  /**
   * Used to sort table data based on employeeNo in ascending  order
   */
  sortEmpNoAZ() {
    this.sortEmpNoAz.emit();
    this.showAsc = !this.showAsc;
    this.showDesc = !this.showDesc;
  }

  /**
   * Used to sort table data based on employeeNo in descending  order
   */
  sortEmpNoZA() {
    this.sortEmpNoZa.emit();
    this.showAsc = !this.showAsc;
    this.showDesc = !this.showDesc;
  }

  sortCompanyNameAZ() {
    this.sortCompanyNameAz.emit();
    this.showCompanyAsc = !this.showCompanyAsc;
    this.showCompanyDesc = !this.showCompanyDesc;
  }

  sortCompanyNameZA() {
    this.sortCompanyNameZa.emit();
    this.showCompanyAsc = !this.showCompanyAsc;
    this.showCompanyDesc = !this.showCompanyDesc;
  }

  sortCompanyIdAZ() {
    this.sortCompanyIdAz.emit();
    this.showCompanyIdAsc = !this.showCompanyIdAsc;
    this.showCompanyIdDesc = !this.showCompanyIdDesc;
  }

  sortCompanyIdZA() {
    this.sortCompanyIdZa.emit();
    this.showCompanyIdAsc = !this.showCompanyIdAsc;
    this.showCompanyIdDesc = !this.showCompanyIdDesc;
  }

  sortfacilityNameAZ() {
    this.sortfacilityNameAz.emit();
    this.showFacilityAsc = !this.showFacilityAsc;
    this.showFacilityDesc = !this.showFacilityDesc;
  }

  sortfacilityNameZA() {
    this.sortfacilityNameZa.emit();
    this.showFacilityAsc = !this.showFacilityAsc;
    this.showFacilityDesc = !this.showFacilityDesc;
  }

  sortTestStartDateAZ() {
    this.sortTestStartDateAz.emit();
    this.showStartDateAsc = !this.showStartDateAsc;
    this.showStartDateDesc = !this.showStartDateDesc;
  }

  sortTestStartDateZA() {
    this.sortTestStartDateZa.emit();
    this.showStartDateAsc = !this.showStartDateAsc;
    this.showStartDateDesc = !this.showStartDateDesc;
  }

  sortTestEndDateAZ() {
    this.sortTestEndDateAz.emit();
    this.showEndDateAsc = !this.showEndDateAsc;
    this.showEndDateDesc = !this.showEndDateDesc;
  }

  sortTestEndDateZA() {
    this.sortTestEndDateZa.emit();
    this.showEndDateAsc = !this.showEndDateAsc;
    this.showEndDateDesc = !this.showEndDateDesc;
  }

  sortCreatedOnDateAZ() {
    this.sortCreatedOnDateAz.emit();
    this.showCreatedOnAsc = !this.showCreatedOnAsc;
    this.showCreatedOnDesc = !this.showCreatedOnDesc;
  }

  sortCreatedOnDateZA() {
    this.sortCreatedOnDateZa.emit();
    this.showCreatedOnAsc = !this.showCreatedOnAsc;
    this.showCreatedOnDesc = !this.showCreatedOnDesc;
  }

  /**
   * Used to change the data on next page click
   * @param eventIndex used to pass the Page index
   */
  onPaginatorPageChange(eventIndex) {
    this.selectedPageChange.emit(eventIndex);
  }

  /**
   * Use to change the status of company grid
   */
  onStatusSelected(event, compnayId) {
    if (event === 'Onboarded') {
      this.clickedOnBoardStatus.emit({ status: 'Onboarded', id: compnayId });
    } else if (event === 'OnHold') {
      this.clickedOnBoardStatus.emit({ status: 'OnHold', id: compnayId });
    } else if (event === 'Inactive') {
      this.clickedOnBoardStatus.emit({ status: 'Inactive', id: compnayId });
    }
  }

  onBoardClick(user) {
    this.clickHere.emit(user);
  }

  showTooltip() {
    this.showHideTooltip = true;
  }

  /**
   * Use to pass event to edit selected user
   * @param user used to pass the selected user
   */
  editUser(user) {
    this.selectedUser.emit(user);
    this.cd.detectChanges();
  }

  /**
   * method used to capture the key typed by user
   * @param event input value
   */

  public onKeySearch(event: any) {
    this.emitFreeTextFormValue.emit(this.keyPressedValue);
    if (this.keyPressedValue.length > 1 || (event.key === 'Backspace' && this.keyPressedValue === '')) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (event.keyCode) {
          this.executeListing(event.target.value);
        }
      }, 1000);
    }
  }

  public executeListing(freeInput) {
    this.freeTextValue.emit(freeInput);
  }

  /**
   * Use to get values from array and object
   */
  getValueHere(user, column) {
    if (user[column] instanceof Array) {
      // tslint:disable-next-line: no-inferrable-types
      let roleName: string = '';
      user[column].map(role => {
        roleName = role.name;
      });
      return roleName;
    }
    if (user[column] instanceof Object) {
      // tslint:disable-next-line: no-inferrable-types
      let deptName: string = '';
      deptName = user[column].departmentName;
      return deptName;
    }
    if (user && user[column]) {
      return user[column];
    } else {
      return 'NA';
    }
  }


  /**
   * Used to view the report
   * @param userDetails used to get user data
   */
  viewReportClick(userDetails) {
    if (userDetails && userDetails.length > 0) {
      const filterData = userDetails.find(ele => ele.status === 'Completed' || ele.status === 'CompletedWithShift');
      if (filterData) {
        const reportUrl = filterData.certusOHSPdfReportPathOnS3;
        window.open(reportUrl, '_blank');
      }
    }
  }

  /**
   * check status for Complete and CompletedWithShift
   * @param diagnosticTests used to get user diagnostic test data
   */
  checkStatus(diagnosticTests) {
    let filterData;
    if (diagnosticTests && diagnosticTests.length > 0) {
      filterData = diagnosticTests.find(ele => ele.status === 'Completed' || ele.status === 'CompletedWithShift');
    }
    if (filterData) {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    if (this.selectedFacilitySub$) {
      this.selectedFacilitySub$.unsubscribe();
    }
    if (this.selectedAllUserDataSub$) {
      this.selectedAllUserDataSub$.unsubscribe();
    }
    if (this.selectAdvanceFlagSub$) {
      this.selectAdvanceFlagSub$.unsubscribe();
    }
    if (this.selectedtTestStatusDataSub$) {
      this.selectedtTestStatusDataSub$.unsubscribe();
    }
  }
}