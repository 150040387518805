export const ROUTE_TEST_SUMMARY = 'test-summary';
export const ROUTE_SUMMARY = 'summary';
export const ROUTE_FACILITY = 'facility';
export const SINGLE_DONUT = 'summary-details';
export const SKELETON_TEST_SUMMARY = 'testSummary';
export const SKELETON_TEST_DONUT = 'totalTestDonut';
export const SKELETON_ALL_FACILITY_CARDS = 'allFacilityCards';
export const SKELETON_FACILITY_CARDS = 'facilityCards';
export const SKELETON_MANAGE_USER = 'manageUser';
export const SKELETON_DISPLAY_USER_TABLE = 'displayUserTable';
export const SKELETON_TEST_SUMMARY_USER_TABLE = 'testSummaryUserTable';

