import {
  OnSuccessMessageActionType,
  ToasterAction,
  ToasterActionType
} from './toaster.action';
import { ToasterState } from './toaster.model';

export function toasterReducer(
  state: ToasterState = new ToasterState(),
  action: ToasterAction
): ToasterState {
  switch (action.type) {
    case ToasterActionType.ON_SUCCESS_MESSAGE:
      const existingToasterStateSuccess = Object.assign({}, state.toasterData);
      existingToasterStateSuccess.title = action.payload.title;
      existingToasterStateSuccess.description = action.payload.description;
      existingToasterStateSuccess.type = 'SUCCESS';
      return {
        ...state,
        toasterData: existingToasterStateSuccess
      };
    case ToasterActionType.ON_ERROR_MESSAGE:
      const existingToasterStateError = Object.assign({}, state.toasterData);
      existingToasterStateError.title = action.payload.title;
      existingToasterStateError.description = action.payload.description;
      existingToasterStateError.type = 'ERROR';
      return {
        ...state,
        toasterData: existingToasterStateError
      };

      case ToasterActionType.ON_WARNING_MESSAGE:
        const existingToasterStateWarning = Object.assign({}, state.toasterData);
        existingToasterStateWarning.title = action.payload.title;
        existingToasterStateWarning.description = action.payload.description;
        existingToasterStateWarning.type = 'WARNING';
        return {
          ...state,
          toasterData: existingToasterStateWarning
        };

    case ToasterActionType.CLEAR_TOASTER:
      state = { toasterData: null };
      return {
        ...state
      };
    default:
      return state;
  }
}
