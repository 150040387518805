import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpTokenInterceptor } from './interceptors/http.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { CoreComponents } from './core-component';
import { SharedModule } from '../shared/shared.module';
import { skeletonReducer } from './store/skeleton-store/skeleton.reducer';
import { StoreModule } from '@ngrx/store';
import { toasterReducer } from './store/toaster-store/toaster.reducer';

import { facilityReducer } from './store/facility-store/facility.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CoreEffects } from './store/core-effects/core.effects';
import { RolesGuard } from './guards/roles.guard';
import { RoutePermissionGuard } from './guards/route-permission.guard';

const reducers = {};

@NgModule({
  declarations: [...CoreComponents],
  imports: [
    HttpClientModule,
    SharedModule,
    StoreModule.forFeature('skeletonReducer', skeletonReducer),
    StoreModule.forFeature('toasterReducer', toasterReducer),
    StoreModule.forFeature('facilityReducer', facilityReducer),
    EffectsModule.forFeature([CoreEffects])
  ],
  exports: [HttpClientModule, ...CoreComponents],
  providers: [
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    RolesGuard, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    RoutePermissionGuard, {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {}
