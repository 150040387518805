import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatStepperModule} from '@angular/material/stepper';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MatRadioModule, MatSlideToggleModule, MatGridListModule, MatTableModule,
  MatFormFieldModule, MatSortModule, MatSidenavModule, MatDatepickerModule, MatPaginatorModule,
  MatNativeDateModule, MatChipsModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatDialogModule,
} from '@angular/material/';

const MaterialModule = [
  MatButtonModule,
  MatExpansionModule,
  MatToolbarModule,
  MatMenuModule,
  MatSelectModule,
  MatInputModule,
  MatCheckboxModule,
  MatCardModule,
  MatListModule,
  MatIconModule,
  MatTooltipModule,
  MatRadioModule,
  MatGridListModule,
  MatTableModule,
  MatFormFieldModule,
  MatSortModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatDatepickerModule,
  MatPaginatorModule,
  MatNativeDateModule,
  MatChipsModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatStepperModule,
  MatExpansionModule
];

export { MaterialModule };
