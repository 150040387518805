import { FacilityActions, FacilityActionTypes } from './facility.actions';
import { FacilityState } from '../../models/facility.model';

export function facilityReducer(state: FacilityState = new FacilityState(), action: FacilityActions):
FacilityState {
  switch (action.type) {
    case FacilityActionTypes.FETCH_ALL_FACILITY_SUCCESS:
        const userFacilities = action.payload;
        let selectedFacility = action.payload[0];
        if (userFacilities.length > 1) {
        const allFacility = {
          facilityId: -1,
          facilityName: 'All Facility',
          facilityHeadFirstName: '',
          facilityHeadLastName: null,
          numberOfUsers: 0,
          totalTestsExpected: ''
        };
        selectedFacility = allFacility;
        userFacilities.push(allFacility);
      }
        return {
          ...state,
          userFacilities,
          selectedFacility
      };
      case FacilityActionTypes.SET_SELECTED_FACILITY:
      return {
          ...state,
          selectedFacility: action.payload
      };
      case FacilityActionTypes.SET_SELECTED_COMPANY:
        return {
            ...state,
            selectedCompany: action.selectedCompanyId
        };

        case FacilityActionTypes.SET_CURRENT_COMPANY_DATA:
          return {
              ...state,
              currentCompanyDataToDropdown: action.companyData
          };

      case FacilityActionTypes.CLEAR_FACILITY:
      state = {userFacilities: [],
        selectedFacility: {
        facilityId: null,
        facilityName: '',
        facilityHeadFirstName: '',
        facilityHeadLastName: '',
        numberOfUsers: null,
        totalTestsExpected: '',
      },
      selectedCompany: '',
      currentRolePermission: null,
      companyDetail: [],
      setCompanyDropDownFlag: null,
      setSelectedCompanyFacility: {
        facility: null,
        company: null
      },
      facilityDropdownFlag: null,
      allCompanyNames: null,
      currentCompanyDataToDropdown: null

    };
      return {
        ...state
      };
      case FacilityActionTypes.FETCH_COMPANY_DETAILS_SUCCESS:
            return {
                ...state,
                companyDetail: action.payload
            };
        case FacilityActionTypes.CLEAR_FETCH_COMPANY_DETAILS:
            const clearCompanyDetails = null;
            return {
                ...state,
                companyDetail: clearCompanyDetails
            };
        case FacilityActionTypes.SET_COMPANY_DROPDOWN_FLAG:
            return {
                ...state,
                setCompanyDropDownFlag: action.flag
            };
        case FacilityActionTypes.SET_SELECTED_FACILITY_COMPANY:
            return {
              ...state,
              setSelectedCompanyFacility: action.facilityCompanyObject
            };
        case FacilityActionTypes.TOGGLE_FACILITY_DROPDOWN:
          return {
            ...state,
            facilityDropdownFlag: action.facilityFlag
          };
        case FacilityActionTypes.FETCH_ALL_COMPANY_NAME_SUCCESS:
          return {
            ...state,
            allCompanyNames: action.payload
          };
        case FacilityActionTypes.CLEAR_FETCH_ALL_COMPANY_NAME:
          const companyNames = null;
          return {
            ...state,
            allCompanyNames: companyNames
          };
    default:
      return state;
  }
}
