import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ToasterState } from './toaster.model';

export const getToasterState = createFeatureSelector('toasterReducer');

export const selectToastSummary = createSelector(
  getToasterState,
  (state: ToasterState) => {
    return state.toasterData;
  }
);
