import { SideNavComponent } from './components/side-nav/side-nav.component';
import { InteriorPageComponent } from './components/interior-page/interior-page.component';
import { HeaderComponent } from './components/header-section/header.component';

const CoreComponents = [
    SideNavComponent,
    InteriorPageComponent,
    HeaderComponent
];

export { CoreComponents };