import { Action } from '@ngrx/store';

export enum SkeletonActionType {
  SHOW_SKELETON = 'Skeleton: SHOW_SKELETON',
  HIDE_SKELETON = 'Skeleton: HIDE_SKELETON'
}

export class ShowSkeletonLoader implements Action {
  readonly type = SkeletonActionType.SHOW_SKELETON;
  constructor(public payload: any) {}
}
export class HideSkeletonLoader implements Action {
  readonly type = SkeletonActionType.HIDE_SKELETON;
  constructor(public payload: any) {}
}

export type SkeletonAction = ShowSkeletonLoader | HideSkeletonLoader;
