// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiBaseUrl: 'https://api.certusohs.com' // Prod
  // apiBaseUrl: 'http://demo.certusohs.com:8080' // demo
  // apiBaseUrl: 'http://3.134.243.115:8080' //  new dev prod
};
