import { Component, OnInit, OnDestroy, DoCheck, OnChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { AppState } from 'src/app/app.model';
import { Facility } from 'src/app/user-management/facilities/facilities.model';
import {
  FetchAllFacility,
  SetSelectedFacility,
  SetSelectedCompany,
  SetSelectedFacilityCompany,
  FetchAllCompanyNames
} from '../../store/facility-store/facility.actions';
import { Observable, Subscription } from 'rxjs';
import {
  selectUserFacilities,
  selectSelectedFacility,
  selectSelectedCompany,
  getCompanyDetail,
  setCompanyDropdownFlag,
  setFacilityFlag,
  selectAllCompanyNames,
  setCurrentCompanyData
} from '../../store/facility-store/facility.selectors';
import { SetSelectedMonth, ClearSetSelectedMonth } from 'src/app/test-summary/store/test-summary.action';
import { CompanyDetails, ListViews, CompanyList } from 'src/app/workOrder-management/Add-workorder/Add-workorder.model';
import { FormControl } from '@angular/forms';
import { AddWorkOrderService } from 'src/app/workOrder-management/Add-workorder/Add-workorder.service';
import { DomSanitizer } from '@angular/platform-browser';
import { getSelectedWorkOrderInfo } from 'src/app/workOrder-management/store/workOrder-management.selectors';

@Component({
  selector: 'cer-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public selectedCompanyName = 'Delta Airlines';
  public usersFacilitySub$: Subscription;
  public selectedFacilitySub$: Subscription;
  public selectedCompanySub$: Subscription;
  public selectedSingleFacilitySub$: Subscription;
  public selectsetCurrentCompanyDataSub$: Subscription;
  public selectSelectedWorkrderDataSub$: Subscription;
  public getCompanyDetailSub$: Subscription;
  public allFacility: any;
  public selectedFacility: string;
  public selectedCompany: string;
  public facilityInputText: string;
  public permission: string;
  public selectedCompanyCertus: string;
  public companyInputText: string;
  public selectedFacilityID: number;
  public headerFlag = false;
  public decodedInfo;
  public displayLogo;
  public companyDetails;
  public selectedCompanyFlag = false;
  public workorderRespData;

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private addWorkOrderService: AddWorkOrderService,
    private sanitizer: DomSanitizer
  ) { }
  companyControl = new FormControl();
  public usersFacility$: Observable<Facility[]> = this.store.select(selectUserFacilities);
  public selectedFacility$: Observable<Facility> = this.store.select(selectSelectedFacility);
  public selectedCompany$: Observable<string> = this.store.select(selectSelectedCompany);
  public setCompanyDropdownFlag$: Observable<boolean> = this.store.select(setCompanyDropdownFlag);
  public setFacilityFlag$: Observable<boolean> = this.store.select(setFacilityFlag);
  public selectAllCompanyNames$: Observable<CompanyList[]> = this.store.select(selectAllCompanyNames);
  public selectsetCurrentCompanyData$: Observable<any> = this.store.select(setCurrentCompanyData);
  public getSelectSelectedWorkrderData$: Observable<string> = this.store.select(getSelectedWorkOrderInfo);

  ngOnInit() {
    this.companyDetails = [];
    this.allFacility = [];
    const tokenData = localStorage.getItem('accessToken');
    localStorage.setItem('workorderFlag', 'false');
    this.decodedInfo = jwt_decode(tokenData);
    const selectedCompanyId: string = this.decodedInfo.companyId;
    this.permission = this.decodedInfo.permission[0];
    this.setFacilityFlag$.subscribe(flag => {
      if (flag) {
        this.headerFlag = flag;
      } else {
        this.headerFlag = flag;
      }
    });
    this.store.dispatch(new SetSelectedCompany(selectedCompanyId));
    if (this.selectedFacility$) {
      // localStorage.setItem('workorderFlag', 'false');
      this.selectedFacility = null;
      this.selectedFacilitySub$ = this.selectedFacility$.subscribe(data => {
        // localStorage.setItem('workorderFlag', 'false');
        if (data) {
          this.selectedFacility = data.facilityName;
          this.store.dispatch(new ClearSetSelectedMonth());

        }
      });
    }
    if (this.selectedCompany$) {
      this.selectedCompanySub$ = this.selectedCompany$.subscribe(data => {
        if (data) {
          this.selectedCompany = data;
        }
      });
    }
    if (this.permission === 'COMPANY_ADMIN') {
      this.store.dispatch(new FetchAllFacility(this.decodedInfo.companyId, -1));
    } else if (this.permission === 'FACILITY_ADMIN') {
      this.store.dispatch(new FetchAllFacility(this.decodedInfo.companyId, this.decodedInfo.facilityId));
    } else if (this.permission === 'CERTUSOHS_ADMIN') {
      this.showHideCompanyFlag();
      this.DataForCompanyDropDown();
      // this.showSelectedWorkorderCompany();
    }

    this.usersFacilitySub$ = this.usersFacility$.subscribe(data => {
      if (data) {
        this.allFacility = data;
      }
    });
  }

  showHideCompanyFlag() {
    this.setCompanyDropdownFlag$.subscribe(res => {
      if (res) {
        this.selectedCompanyFlag = res;
        this.selectsetCurrentCompanyData$.subscribe(data => {
          if (data && this.companyDetails) {
            const createdCompany = this.companyDetails.filter(element => {
              if (element.companyNo === data.companyNo) {
                return element.companyName;
              }
            });
            this.companyControl.setValue(createdCompany[0].companyName);
            if (!data.workOrderId) {
              this.store.dispatch(new FetchAllFacility(createdCompany[0].id, -1));
              this.store.dispatch(new SetSelectedCompany(createdCompany[0].id));
            } else {
              this.store.dispatch(new SetSelectedCompany(createdCompany[0].id));
            }
          }
        });
      } else {
        this.selectedCompanyFlag = res;
      }
    });
  }

  DataForCompanyDropDown() {
    this.store.dispatch(new FetchAllCompanyNames());
    if (this.selectAllCompanyNames$) {
      this.getCompanyDetailSub$ = this.selectAllCompanyNames$.subscribe(res => {
        if (res) {
          this.companyDetails = res;
          this.companyControl.setValue(this.companyDetails[0].companyName);
          if (!this.selectedCompanyCertus) {
            this.store.dispatch(new FetchAllFacility(this.companyDetails[0].id, -1));
            this.store.dispatch(new SetSelectedCompany(this.companyDetails[0].id));
          }
        }
      });
    }
  }

  getSelectedCompanyId(selectedcompanyName) {
    localStorage.setItem('workorderFlag', 'false');
    if (this.companyDetails) {
      this.companyDetails.find(ele => {
        if (ele['companyName'] === selectedcompanyName) {
          this.selectedCompanyCertus = ele['id'].toString();
        }
      });
    }
    if (this.selectedCompanyCertus) {
      this.store.dispatch(new FetchAllFacility(this.selectedCompanyCertus, -1));
      this.store.dispatch(new SetSelectedCompany(this.selectedCompanyCertus));
    }
  }
  getSelectedFacilityId(selectedFacilityName) {
    localStorage.setItem('workorderFlag', 'false');
    if (this.allFacility) {
      this.allFacility.find(ele => {
        if (ele['facilityName'] === selectedFacilityName) {
          this.selectedFacilityID = ele['facilityId'] ? ele['facilityId'] : ele['id'];
        }
      });
    }
    const selectedFacility = this.allFacility.find(facility => {
      if (facility.facilityId) {
        return facility.facilityId === this.selectedFacilityID;
      } else {
        return facility.id === this.selectedFacilityID;
      }
    });
    this.store.dispatch(new SetSelectedFacility(selectedFacility));
    const defaultMonth = 'month';
    this.store.dispatch(new SetSelectedMonth(defaultMonth));
    const selectedFacilityCompany = {
      facility: this.selectedFacilityID,
      company: this.selectedCompanyCertus ? this.selectedCompanyCertus : this.companyDetails[0].id
    };
    if (this.permission === 'CERTUSOHS_ADMIN') {
      this.store.dispatch(new SetSelectedFacilityCompany(selectedFacilityCompany));
    }
  }
  searchCompany(event) {
    this.companyInputText = this.companyControl.value;
    if (event.target.value.length > 0) {
      if (this.companyInputText.length > 2) {
        this.addWorkOrderService.getAllCompanies(this.companyInputText).subscribe(response => {
          this.companyDetails = response['companyListViews'];
        });
      } else if (this.companyInputText.length < 2 && (event.key === 'Backspace' || this.companyInputText === '')) {
        this.store.dispatch(new FetchAllCompanyNames());
      }
    }
  }
  searchFacility(event) {
    if (this.permission === 'CERTUSOHS_ADMIN') {
      this.selectedCompanyCertus = this.selectedCompanyCertus ? this.selectedCompanyCertus : '1';
    }
    if (event.target.value.length > 0) {
      if (this.selectedFacility.length > 2) {
        this.addWorkOrderService.getAllFacilities
          (this.selectedCompanyCertus ? +this.selectedCompanyCertus : this.decodedInfo.companyId, this.selectedFacility)
          .subscribe(response => {
            this.allFacility = response['facilityListViews'];
          });
      }
    } else {
      this.addWorkOrderService.getAllFacilitiesList
        (this.selectedCompanyCertus ? +this.selectedCompanyCertus : this.decodedInfo.companyId).subscribe(
          facilityResponse => {
            this.allFacility = [];
            this.allFacility = facilityResponse['facilityListViews'];
            const allFacility = {
              id: null,
              facilityId: -1,
              facilityName: 'All Facility',
              facilityHeadFirstName: '',
              facilityHeadLastName: null,
              numberOfUsers: 0,
              totalTestsExpected: ''
            };
            if (this.allFacility.length > 1) {
              this.allFacility.push(allFacility);
            }
            // this.allFacility.push(allFacility);
          });
    }
  }

  ngOnDestroy() {
    if (this.usersFacilitySub$) {
      this.usersFacilitySub$.unsubscribe();
    }
    if (this.selectedFacilitySub$) {
      this.selectedFacilitySub$.unsubscribe();
    }
    if (this.getCompanyDetailSub$) {
      this.getCompanyDetailSub$.unsubscribe();
    }
    // if (this.selectsetCurrentCompanyDataSub$) {
    //   this.selectsetCurrentCompanyDataSub$.unsubscribe();
    // }
  }
}
