// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //  apiBaseUrl: 'http://dev.certusohs.com:8080'
  //  apiBaseUrl: 'http://demo.certusohs.com:8080' // demo
  //  apiBaseUrl: 'http://18.190.0.148:8080'
  //  apiBaseUrl: 'http://192.168.0.153:8080'  // Sajid
  //  apiBaseUrl: 'http://3.134.243.115:8080'  // new DEV
  apiBaseUrl: 'https://api.certusohs.com' // Prod
  // apiBaseUrl: 'https://ngn.certusohs.com'   // new prod
};
