import {
  Component,
  OnInit,
  Input,
  Output,
  ElementRef,
  EventEmitter
} from '@angular/core';
import * as d3 from 'd3-selection';
import 'd3-transition';
import * as d3Scale from 'd3-scale';
import * as d3Shape from 'd3-shape';
import { DonutConfigModel } from './donut-cofig.model';

@Component({
  selector: 'cer-donut-graph',
  templateUrl: './donut-graph.component.html',
  styleUrls: ['./donut-graph.component.scss']
})
export class DonutGraphComponent implements OnInit {
  constructor(private element: ElementRef) { }
  @Input() donutData;
  @Input() legendData;
  @Input() donutConfig: DonutConfigModel;
  @Input() testDonutResponse;
  @Output() selectedArcUser = new EventEmitter();
  @Output() selectedCenterArc = new EventEmitter();
  private svg: any;
  private svgWidth: number;
  private svgHeight: number;
  private radius: number;
  private arc: any;
  private arcOver: any;
  public arcText: number;
  private pie: any;
  private color: any;
  public svgId: string;

  ngOnInit() {
    if (this.testDonutResponse) {
      this.initSvg(this.testDonutResponse);
    }
  }

  private initSvg(legendData) {
    this.svg = d3
      .select(this.element.nativeElement)
      .select<SVGSVGElement>('svg');
    this.svgWidth = 260;
    this.svgHeight = 260;
    this.radius = Math.min(this.svgWidth, this.svgHeight) / 3;
    if (this.testDonutResponse) {
      if (this.testDonutResponse[this.donutConfig.totalCountKey] !== 0) {
        this.color = d3Scale.scaleOrdinal().range(this.donutConfig.allColors);
      } else {
        this.color = d3Scale.scaleOrdinal().range(['#cdd1d4']);
      }
    }
    this.arc = d3Shape
      .arc()
      .outerRadius(this.radius - 1)
      .innerRadius(this.radius - 45);
    this.arcOver = d3Shape
      .arc()
      .outerRadius(this.radius - 1)
      .innerRadius(this.radius - 45);
    this.pie = d3Shape
      .pie()
      .sort(null)
      .value(d => {
        if (this.testDonutResponse[this.donutConfig.totalCountKey] === 0) {
          return d[this.donutConfig.sortKey] = '1';
        }
        return d[this.donutConfig.sortKey];
      }
      );
    this.svg = this.svg
      .append('g')
      .attr('transform', 'translate(' + '110' + ',' + '110' + ')');
    this.drawChart(this.legendData);
  }
  private drawChart(data: any[]) {
    const g = this.svg
      .selectAll('.arc')
      .data(this.pie(data))
      .enter()
      .append('g')
      .attr('class', 'arc');
    g.append('path')
      .attr('d', this.arc)
      .style('fill', d => this.color(d.data[this.donutConfig.status]))
      .on('click', d => {
        this.selectedArcUser.emit(d);
      });
    g.append('text')
      .attr('transform', d => 'translate(' + this.arc.centroid(d) + ')')
      .attr('dy', '.100em')
      .on('click', d => {
        this.selectedArcUser.emit(d);
      })
      .text(d => {
        if (d.data[this.donutConfig.textKey] === '0' || this.testDonutResponse[this.donutConfig.totalCountKey] === 0) {
          return '';
        }
        return d.data[this.donutConfig.textKey];
      });
    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('font-size', '1.3em')
      .attr('y', 4)
      .text(d => {
        if (this.testDonutResponse[this.donutConfig.totalCountKey] === 0) {
          return '0';
        }
        return this.testDonutResponse[this.donutConfig.totalCountKey];
      }
      )
      .on(' middle click', d => {
        this.selectedCenterArc.emit(d);
      });
  }
}
