import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, AbstractControl, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { TranslateService } from '@ngx-translate/core';
import { ResetPassword } from './reset-password.model';
import { ResetPasswordService } from './reset-password.service';
import { ROUTE_TEST_SUMMARY, ROUTE_FACILITY, ROUTE_SUMMARY } from 'src/app/test-summary/test-summary.config';

@Component({
    selector: 'cer-resetPassword',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

    public resetPasswordForm: FormGroup;
    public tokenData: string;
    public facilityId: string;
    constructor(
        private router: Router,
        private resetPasswordService: ResetPasswordService,
        private formBuilder: FormBuilder,
        private translate: TranslateService) {
            this.translate.setDefaultLang('en');
    }

    /**
     * Use to create resactive login form
     */
    ngOnInit() {
        this.resetPasswordForm = this.formBuilder.group({
            newPassword: ['', [Validators.required, Validators.minLength(4) ]],
            confirmPassword: ['', [Validators.required, Validators.minLength(4)]],
        }, { validator: this.passwordConfirming });
        this.tokenData = localStorage.getItem('accessToken');
    }

    /**
     * Use to reset the password for first time login user
     */
    onSubmit() {
        const requestBody: ResetPassword = {
            newPassword: this.resetPasswordForm.get('newPassword').value.trim(),
            confirmPassword: this.resetPasswordForm.get('confirmPassword').value.trim()
        };
        this.resetPasswordService.resetPassword(requestBody).subscribe(response => {
            if (response) {
                localStorage.setItem('accessToken', response.accessToken);
                const tokenData = localStorage.getItem('accessToken');
                const decodedInfo = jwt_decode(tokenData);
                this.facilityId = decodedInfo.facilityId;
                this.router.navigateByUrl(`${ROUTE_TEST_SUMMARY}/${ROUTE_FACILITY}/${ROUTE_SUMMARY}`);
            }
        }, error => {
        });
    }

    /**
     * Used to implement validation on password and confirm password
     * @param c use to create instance of AbstactControl
     */
    passwordConfirming(c: AbstractControl): { invalid: boolean } {
        if (c.get('newPassword').value !== c.get('confirmPassword').value) {
            return { invalid: true };
        }
    }
}
