import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/auth.Index';
import { selectToastSummary } from '../../store/toaster-store/toaster.selectors';
import { TranslateService } from '@ngx-translate/core';
import * as jwt_decode from 'jwt-decode';
import { ClearToaster } from '../../store/toaster-store/toaster.action';

@Component({
  selector: 'cer-interior-page',
  templateUrl: './interior-page.component.html',
  styleUrls: ['./interior-page.component.scss']
})
export class InteriorPageComponent implements OnInit, OnDestroy {

  private toastrSub$: Subscription;
  public toasterSummary: string;

  public toastrSummary$: Observable<any> = this.store.select(
    selectToastSummary
  );

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    const tokenData = localStorage.getItem('accessToken');
    if (tokenData) {
      const decodedInfo = jwt_decode(tokenData);
      this.translate.setDefaultLang(decodedInfo.language);
    } else {
      this.translate.setDefaultLang('en');
    }
    this.toastrSub$ = this.toastrSummary$.subscribe(res => {
      this.toasterSummary = res;
      setTimeout(() => { this.store.dispatch(new ClearToaster()); }, 10000);
    });
  }

  ngOnDestroy() {
    if (this.toastrSub$) {
      this.toastrSub$.unsubscribe();
    }

  }
}
