import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
    selector: '[firstLetterCapital]'
})
export class FirstLetterCapitalDirective {
    constructor(private elementRef: ElementRef) {
    }
    @HostListener('input') onInput() {
        const inputString = this.elementRef.nativeElement.value;
        if (inputString) {
            const finalInpurtString = inputString[0].toUpperCase() + inputString.slice(1);
            this.elementRef.nativeElement.value = finalInpurtString;
        }
    }
}