import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.model';
import { environment } from 'src/environments/environment';
import {
  SKELETON_MANAGE_USER,
  SKELETON_DISPLAY_USER_TABLE
} from 'src/app/test-summary/test-summary.config';
import {
  ShowSkeletonLoader,
  HideSkeletonLoader
} from 'src/app/core/store/skeleton-store/skeleton.action';
@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {
  constructor(private http: HttpClient, private store: Store<AppState>) { }

  public getUsersList(
    companyId: number,
    facilityId: number,
    pageIndex: number,
    pageSize?: number,
    orderByKey?: string,
    directionKey?: string,
  ) {
    const actionPaylod = {
      parentKey: SKELETON_MANAGE_USER,
      childKey: SKELETON_DISPLAY_USER_TABLE
    };
    this.store.dispatch(new ShowSkeletonLoader(actionPaylod));
    return this.http
      .get(
        `${environment.apiBaseUrl}/companies/${companyId}/facilities/${facilityId}/users?page=${
        pageIndex}&size=${pageSize}&orderBy=${orderByKey}&direction=${directionKey}`
      )
      .pipe(
        map(response => {
          this.store.dispatch(new HideSkeletonLoader(actionPaylod));
          return response;
        })
      );
  }

  public getSearchedUsersList(
    companyId: string,
    facilityId: number,
    pageIndex: number,
    pageSize?: number,
    orderByKey?: string,
    directionKey?: string,
    firstName?: string,
    lastName?: string,
    employeeId?: string,
    depatmentName?: string,
    roleName?: string,
    freeText?: string
  ) {
    return this.http
      .get(
        `${environment.apiBaseUrl}/companies/${companyId}/facilities/${
        facilityId}/users?&page=${pageIndex}&size=${pageSize}&orderBy=${
        orderByKey}&direction=${directionKey}&searchByFields=firstName:${
        firstName},lastName:${lastName},employeeNo:${
        employeeId},department.departmentName:${
        depatmentName},roles.name:${roleName}&freeTextSearch=${freeText} `
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  public getFreeTextSearchData(
    companyId: string,
    facilityId: number,
    pageIndex: number,
    pageSize?: number,
    orderByKey?: string,
    directionKey?: string,
    firstName?: string,
    lastName?: string,
    employeeId?: string,
    depatmentName?: string,
    roleName?: string,
    freeText?: string
  ) {
    return this.http
      .get(
        `${environment.apiBaseUrl}/companies/${companyId}/facilities/${
        facilityId}/users?&page=${pageIndex}&size=${pageSize}&orderBy=${
        orderByKey}&direction=${directionKey}&searchByFields=firstName:${
        firstName},lastName:${lastName},employeeNo:${
        employeeId},department.departmentName:${
        depatmentName},roles.name:${roleName}&freeTextSearch=${freeText}`
      )
      .pipe(
        map(response => {
          return response;
        })
      );
  }


  public userBulkUpload(companyId: number, file: any) {
    const actionPaylod = {
      parentKey: SKELETON_MANAGE_USER,
      childKey: SKELETON_DISPLAY_USER_TABLE
    };
    return this.http.post(`${environment.apiBaseUrl}/users/bulk/companies/${companyId}`, file).pipe(
      map(response => {
        this.store.dispatch(new HideSkeletonLoader(actionPaylod));
        return response;
      })
    );
  }

  public getUserDetailsById(userId: string) {
    return this.http
      .get(`${environment.apiBaseUrl}/users/${userId}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  public getCompanyLogo(companyId: number) {
    return this.http
    .get(`${environment.apiBaseUrl}/companies/${companyId}/fetchCompanyLogo`)
    .pipe(
      map(response => {
        return response;
      })
    );
  }
}