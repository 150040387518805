import {
    Component, OnInit,
    Input,
    ViewChild
} from '@angular/core';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

@Component({
    selector: 'cer-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
    @Input() dataSource;
    @Input() displayedColumns;
    @Input() columnNames;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, null) paginator: MatPaginator;
    constructor() { }

    /**
     * Used to display table data
     */
    ngOnInit() {
        this.dataSource = new MatTableDataSource<any>(this.dataSource);
        this.dataSource.sort = this.sort;
    }

    /**
     * Used to get color code
     * @param bullectColor used to pass color code
     */
    getBulletColor(bullectColor) {
        return { background: bullectColor };

    }
}