import { createSelector, createFeatureSelector } from '@ngrx/store';
import { UserManagementState } from '../../models/user-management.model';
export const getProductsState = createFeatureSelector('userManagementReducer');

export const selectUserFacility = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.userFacility;
    }
);

export const selectSingleFacility = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.singleFacility;
    }
);

export const selectCompanyFacilityCardsData = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.setCompanyFacilityCardsData;
    }
);
export const selectUsersList = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.allUsers;
    }
);

export const selectedRouteId = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.routeID;
    }
);

export const searchedtUsersList = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.allUsers;
    }
);


export const selectedUsersByCompany = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.allUsers;
    }
);


export const selectedAddedUser = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.addedUser;
    }
);

export const selectedRoles = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.allRoles;
    }
);

export const selectedDept = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.allDept;
    }
);

export const allTestDetails = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.allTest;
    }
);

export const selectUpdatedUser = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.updateUser;
    }
);

export const selectSearchResult = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.allUsers;
    }
);
export const clearSelectedAssignTest = createSelector(
    getProductsState,
    (state: UserManagementState) => {
        return state.allTestClear;
    }
);

