import { Action } from '@ngrx/store';
import { BreadCrumbs, CustomDate } from './test-summary.model';
import { Userlist } from 'src/app/user-management/manage-users/manage-users.model';

export enum SummaryActionType {
  FETCH_DONUT_DATA = 'Summary: FETCH_DONUT_DATA',
  FETCH_DONUT_DATA_SUCCESS = 'Summary:FETCH_DONUT_DATA_SUCCESS',
  FETCH_DONUT_DATA_ERROR = 'Summary:FETCH_DONUT_DATA_ERROR',
  ADD_BREADCRUMB = 'summary:ADD_BREADCRUMB',
  FETCH_TEST_STATUS_USERS = 'Summary: FETCH_TEST_STATUS_USERS',
  FETCH_TEST_STATUS_USERS_SUCCESS = 'Summary:FETCH_TEST_STATUS_USERS_SUCCESS',
  FETCH_FILTERED_USER = 'Summary: FETCH_FILTERED_USER',
  FETCH_FILTERED_USER_SUCCESS = 'Summary:FETCH_FILTERED_USER_SUCCESS',
  FETCH_ALL_USER_DATA = 'Summary: FETCH_ALL_USER_DATA',
  FETCH_ALL_USER_DATA_SUCCESS = 'Summary:FETCH_ALL_USER_DATA_SUCCESS',
  SET_CUSTOM_DATE_TO_DROPDOWN = 'Summary: SET_CUSTOM_DATE_TO_DROPDOWN',
  SET_SELECTED_MONTH = 'Summary: SET_SELECTED_MONTH',
  CLEAR_SET_SELECTED_MONTH = 'Summary: CLEAR_SET_SELECTED_MONTH'
}
export class FetchTestDonut implements Action {
  readonly type = SummaryActionType.FETCH_DONUT_DATA;
  constructor(
    public companyId: string,
    public facilityId: number,
    public fromDate?: string,
    public endDate?: string,
    public workOrderId?: any
  ) { }
}
export class FetchTestDonutSuccess implements Action {
  readonly type = SummaryActionType.FETCH_DONUT_DATA_SUCCESS;
  constructor(public payload: any) { }
}
export class FetchTestDonutError implements Action {
  readonly type = SummaryActionType.FETCH_DONUT_DATA_ERROR;
  constructor(public payload: any) { }
}
export class FetchTestStatusUsersData implements Action {
  readonly type = SummaryActionType.FETCH_TEST_STATUS_USERS;
  constructor(
    public compantId: string,
    public facilityId: number,
    public diagnosticTestId: number,
    public status: string,
    public fromDate: string,
    public toDate: string,
    public pageIndex?: number,
    public pageSize?: number,
    public orderByKey?: string,
    public directionKey?: string,
    public diagnosticTeststatus?,
    public workOrderIds?
  ) { }
}
export class FetchTestStatusUsersDataSuccess implements Action {
  readonly type = SummaryActionType.FETCH_TEST_STATUS_USERS_SUCCESS;
  constructor(public payload: any) { }
}
export class FetchFilteredUsersData implements Action {
  readonly type = SummaryActionType.FETCH_FILTERED_USER;
  constructor(
    public compantId: string,
    public facilityId: number,
    public diagnosticTestId: number,
    public status: string,
    public fromDate?: string,
    public toDate?: string,
    public pageIndex?: number,
    public pageSize?: number,
    public orderByKey?: string,
    public directionKey?: string,
    public firstName?: string,
    public lastName?: string,
    public employeeId?: string,
    public depatmentName?: string,
    public roleName?: string,
    public freeText?: string
  ) { }
}
export class FetchFilteredUsersDataSuccess implements Action {
  readonly type = SummaryActionType.FETCH_FILTERED_USER_SUCCESS;
  constructor(public payload: any) { }
}
export class FetchAllUsersData implements Action {
  readonly type = SummaryActionType.FETCH_ALL_USER_DATA;
  constructor(
    public compantId: string,
    public facilityId: number,
    public diagnosticTestId: number,
    public status: string,
    public fromDate: string,
    public toDate: string,
    public pageIndex?: number,
    public pageSize?: number
  ) { }
}
export class FetchAllUsersDataSuccess implements Action {
  readonly type = SummaryActionType.FETCH_ALL_USER_DATA_SUCCESS;
  constructor(public payload: any) { }
}
export class OnAddBreadCrumb implements Action {
  readonly type = SummaryActionType.ADD_BREADCRUMB;
  constructor(public payload: BreadCrumbs) { }
}
export class SetCustomSelectedDateToDropdown implements Action {
  readonly type  = SummaryActionType.SET_CUSTOM_DATE_TO_DROPDOWN;
  constructor(public customSelectedDate: CustomDate) { }
}
export class SetSelectedMonth implements Action {
  readonly type = SummaryActionType.SET_SELECTED_MONTH;
  constructor(public selectedMonth: string) { }
}
export class ClearSetSelectedMonth implements Action {
  readonly type = SummaryActionType.CLEAR_SET_SELECTED_MONTH;
  constructor() { }
}
export type TestSummaryAction =
  | FetchTestDonut
  | FetchTestDonutSuccess
  | FetchTestDonutError
  | FetchTestStatusUsersData
  | FetchTestStatusUsersDataSuccess
  | OnAddBreadCrumb
  | FetchFilteredUsersData
  | FetchFilteredUsersDataSuccess
  | FetchAllUsersData
  | FetchAllUsersDataSuccess
  | SetCustomSelectedDateToDropdown
  | SetSelectedMonth
  | ClearSetSelectedMonth;


