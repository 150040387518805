import { Directive, Output, EventEmitter, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[clickOutside]'
})

export class ClickOutsideDirective {
    constructor(private elementRef: ElementRef) { }
    @Output() public clickOutside = new EventEmitter<Event>();
    @HostListener('document:click', ['$event.target'])
    public onClick(event: MouseEvent) {
        const clickedInside = this.elementRef.nativeElement.contains(event);
        const clickedInCal = (document.getElementsByClassName('cdk-overlay-pane').length === 0) ? false : true ;
        const clickedInStatus = (document.getElementsByClassName('cdk-overlay-pane').length === 0) ? false : true ;
        if (!clickedInStatus && !clickedInside && !clickedInCal) {
            if (clickedInStatus === true) {
                this.clickOutside.emit();
            }
        }
    }
}