import { Action } from '@ngrx/store';

export enum ToasterActionType {
  ON_SUCCESS_MESSAGE = 'ON_SUCCESS_MESSAGE',
  ON_ERROR_MESSAGE = 'ON_ERROR_MESSAGE',
  ON_WARNING_MESSAGE = 'ON_WARNING_MESSAGE',
  ON_COMPLETE_MESSAGE = 'ON_COMPLETE_MESSAGE',
  CLEAR_TOASTER = 'CLEAN_TOASTER'
}

export class OnSuccessMessageActionType {
  readonly type = ToasterActionType.ON_SUCCESS_MESSAGE;
  constructor(public payload: any) {}
}

export class OnErrorMessageActionType {
  readonly type = ToasterActionType.ON_ERROR_MESSAGE;
  constructor(public payload: any) {}
}

export class OnWarningMessageActionType {
  readonly type = ToasterActionType.ON_WARNING_MESSAGE;
  constructor(public payload: any) {}
}

export class OnCompleteMessageActionType {
  readonly type = ToasterActionType.ON_COMPLETE_MESSAGE;
  constructor(public payload: any) {}
}
export class ClearToaster {
  readonly type = ToasterActionType.CLEAR_TOASTER;
}

export type ToasterAction =
  | OnSuccessMessageActionType
  | OnErrorMessageActionType
  | OnWarningMessageActionType
  | OnCompleteMessageActionType
  | ClearToaster;
