import { Action } from '@ngrx/store';

export enum ActivityActionType {
    ACTION_LOGIN = 'login'
}

// tslint:disable-next-line: class-name
export class ACTION_LOGIN implements Action {
    readonly type = ActivityActionType.ACTION_LOGIN;
    constructor(public payload: []) { }
}

export type ActivityActions =
    | ACTION_LOGIN;
