import { Facility } from './../../user-management/facilities/facilities.model';
import { CompanyDetails, CompanyList } from 'src/app/workOrder-management/Add-workorder/Add-workorder.model';
import { SelectedFacilityCompany } from '../components/header-section/header.model';

export class FacilityState {
    userFacilities: Facility[];
    selectedFacility: Facility;
    selectedCompany: string;
    currentRolePermission: boolean;
    companyDetail: CompanyDetails[];
    setCompanyDropDownFlag: boolean;
    setSelectedCompanyFacility: SelectedFacilityCompany;
    facilityDropdownFlag: boolean;
    allCompanyNames: CompanyList[];
    currentCompanyDataToDropdown: any;
}