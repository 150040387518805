import {
    Component, Input, OnInit, Output,
    EventEmitter
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'cer-edit-test-card',
    templateUrl: './edit-test-card.component.html',
    styleUrls: ['./edit-test-card.component.scss'],
    providers: [ DatePipe]
})
export class EditTestCardComponent implements OnInit {
    @Input() CardDetails;
    @Output() saveSelectedTest = new EventEmitter();
    @Output() selectedStartDate = new EventEmitter();
    @Output() selectedEndDate = new EventEmitter();
    public today = new Date();
    public isEditable: boolean;
    public isDisable: boolean;
    public convertedStartDate;
    public convertedEndDate;
    public setupTestForm: FormGroup;
    constructor(
        private datePipe: DatePipe,
        private fb: FormBuilder,
        private translate: TranslateService,
    ) {
        this.today.setDate(this.today.getDate());
    }

   async ngOnInit() {
        this.setupTestForm = this.fb.group({
            startDate: ['', [Validators.required]],
            endDate: ['', [Validators.required]]
        },
            { validator: this.dateGraterThan('endDate', 'startDate') }
        );
        if (this.CardDetails) {
            this.isEditable = !(!this.CardDetails.testStartDate && !this.CardDetails.testEndDate);
        }
        this.setupTestForm.patchValue({
            startDate: this.datePipe.transform(this.CardDetails.testStartDate,  'yyyy-MM-dd'),
            endDate: this.datePipe.transform(this.CardDetails.testEndDate,  'yyyy-MM-dd'),
        });
        await new Promise(res => {
            this.translate.get('SETUP_TEST').subscribe(translations => {
              res();
            });
          });
    }

    /**
     * Used to do the validation of start date and end date
     * @param startDate pass as input to compare values
     * @param endDate  pass as input to compare values
     */
    dateGraterThan(startDate: string, endDate: string) {
        return (group: FormGroup): { [key: string]: any } => {
            const selectedStartDate = group.controls[startDate];
            const selectedEndDate = group.controls[endDate];
            if (selectedStartDate.value) {
                if (selectedEndDate.value > selectedStartDate.value) {
                    return {
                        dates : this.translate.instant(
                            'SETUP_TEST.END_DATE_SHOULD_BE_GREATER_THAN_START_DATE'
                          )
                    };
                } else {
                    return {};
                }
            }
        };
    }

    /**
     * Used to set start date
     */
    setStartDate() {
        const startDate = this.setupTestForm.get('startDate').value;
        this.convertedStartDate = this.datePipe.transform(startDate, 'yyyy-MM-dd');
        this.selectedStartDate.emit(this.convertedStartDate);
    }

    /**
     * Used to set end date
     */
    setEndDate() {
        const endDate = this.setupTestForm.get('endDate').value;
        this.convertedEndDate = this.datePipe.transform(endDate, 'yyyy-MM-dd');
        this.selectedEndDate.emit(this.convertedEndDate);
    }

    /**
     * Used to save test and toggle state of card
     */
    saveSingleTest(CardDetails) {
        this.saveSelectedTest.emit(CardDetails);
        this.isEditable = !this.isEditable;
    }

    /**
     * Used to clear start and end date
     */
    clearInput() {
        this.setupTestForm.reset();
        this.convertedStartDate = '';
        this.convertedEndDate = '';
    }

    /**
     * Used to open test in edit mode
     */
    editSingleTest() {
        this.isEditable = !this.isEditable;
    }
}