import { Facility } from './../../../user-management/facilities/facilities.model';
import { Action } from '@ngrx/store';
import { CompanyDetails, CompanyList } from 'src/app/workOrder-management/Add-workorder/Add-workorder.model';
import { SelectedFacilityCompany } from '../../components/header-section/header.model';

export enum FacilityActionTypes {
  SET_SELECTED_FACILITY = 'Facility: SET_SELECTED_FACILITY',
  FETCH_ALL_FACILITY = 'Facility: FETCH_ALL_FACILITY',
  FETCH_ALL_FACILITY_SUCCESS = 'Facility: FETCH_ALL_FACILITY_SUCCESS',
  CLEAR_FACILITY = 'Facility: CLEAN_FACILITY',
  SET_SELECTED_COMPANY = 'Facility: SET_SELECTED_COMPANY',
  FETCH_COMPANY_DETAILS = 'Facility: FETCH_COMPANY_DETAILS',
  FETCH_COMPANY_DETAILS_SUCCESS = 'Facility: FETCH_COMPANY_DETAILS_SUCCESS',
  CLEAR_FETCH_COMPANY_DETAILS = 'Facility: CLEAR_FETCH_COMPANY_DETAILS',
  SET_COMPANY_DROPDOWN_FLAG = 'Facility: SET_COMPANY_DROPDOWN_FLAG',
  SET_SELECTED_FACILITY_COMPANY = 'Facility: SET_SELECTED_FACILITY_COMPANY',
  TOGGLE_FACILITY_DROPDOWN = 'Facility: TOGGLE_FACILITY_DROPDOWN',
  FETCH_ALL_COMPANY_NAME = 'Facility: FETCH_ALL_COMPANY_NAME',
  FETCH_ALL_COMPANY_NAME_SUCCESS = 'Facility: FETCH_ALL_COMPANY_NAME_SUCCESS',
  CLEAR_FETCH_ALL_COMPANY_NAME = 'Facility: CLEAR_FETCH_ALL_COMPANY_NAME',
  SET_CURRENT_COMPANY_DATA = 'Facility: SET_CURRENT_COMPANY_DATA'
}

export class FetchAllFacility implements Action {
  readonly type = FacilityActionTypes.FETCH_ALL_FACILITY;
  constructor(public cId: string, public fId: number) { }
}
export class FetchAllFacilitySuccess implements Action {
  readonly type = FacilityActionTypes.FETCH_ALL_FACILITY_SUCCESS;
  constructor(public payload: Facility[]) { }
}
export class SetSelectedFacility implements Action {
  readonly type = FacilityActionTypes.SET_SELECTED_FACILITY;
  constructor(public payload: Facility) { }
}
export class SetSelectedCompany implements Action {
  readonly type = FacilityActionTypes.SET_SELECTED_COMPANY;
  constructor(public selectedCompanyId: string) { }
}
export class ClearFacility {
  readonly type = FacilityActionTypes.CLEAR_FACILITY;
}
export class FetchCompanyDetails implements Action {
  readonly type = FacilityActionTypes.FETCH_COMPANY_DETAILS;
  constructor() { }
}
export class FetchCompanyDetailsSuccess implements Action {
  readonly type = FacilityActionTypes.FETCH_COMPANY_DETAILS_SUCCESS;
  constructor(public payload: CompanyDetails[]) { }
}
export class ClearFetchCompanyDetails implements Action {
  readonly type = FacilityActionTypes.CLEAR_FETCH_COMPANY_DETAILS;
  constructor() { }
}
export class SetCompanyDropdownFlag implements Action {
  readonly type = FacilityActionTypes.SET_COMPANY_DROPDOWN_FLAG;
  constructor(public flag: boolean) { }
}
export class SetSelectedFacilityCompany implements Action {
  readonly type = FacilityActionTypes.SET_SELECTED_FACILITY_COMPANY;
  constructor(public facilityCompanyObject: SelectedFacilityCompany) { }
}
export class ToggleFacilityDropdown implements Action {
  readonly type = FacilityActionTypes.TOGGLE_FACILITY_DROPDOWN;
  constructor(public facilityFlag: boolean) { }
}
export class FetchAllCompanyNames implements Action {
  readonly type = FacilityActionTypes.FETCH_ALL_COMPANY_NAME;
  constructor() { }
}
export class FetchAllCompanyNamesSuccess implements Action {
  readonly type = FacilityActionTypes.FETCH_ALL_COMPANY_NAME_SUCCESS;
  constructor(public payload: CompanyList[]) { }
}
export class ClearFetchAllCompanyNames implements Action {
  readonly type = FacilityActionTypes.CLEAR_FETCH_ALL_COMPANY_NAME;
  constructor() { }
}

export class SetCurrentCompanyDataToDropdown implements Action {
  readonly type = FacilityActionTypes.SET_CURRENT_COMPANY_DATA;
  constructor(public companyData: any) {
  }
}

export type FacilityActions =
  | SetSelectedFacility
  | FetchAllFacility
  | FetchAllFacilitySuccess
  | SetSelectedCompany
  | ClearFacility
  | FetchCompanyDetails
  | FetchCompanyDetailsSuccess
  | FetchAllCompanyNames
  | FetchAllCompanyNamesSuccess
  | ClearFetchCompanyDetails
  | SetCompanyDropdownFlag
  | SetSelectedFacilityCompany
  | ClearFetchAllCompanyNames
  | ToggleFacilityDropdown
  | SetCurrentCompanyDataToDropdown;
