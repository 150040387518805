import { createSelector, createFeatureSelector } from '@ngrx/store';
import { SummaryState } from './test-summary.model';
export const getProductsState = createFeatureSelector('testSummaryReducer');

export const selectDonut = createSelector(
  getProductsState,
  (state: SummaryState) => {
    return state.donutTestData;
  }
);

export const selectTestStatusUsers = createSelector(
  getProductsState,
  (state: SummaryState) => {
    return state.testStatusUserDetails;
  }
);

export const selectedAllUsersData = createSelector(
  getProductsState,
  (state: SummaryState) => {
    return state.allUserData;
  }
);

export const selectDonutDataError = createSelector(
  getProductsState,
  (state: SummaryState) => {
    return state.donutDataError;
  }
);

export const selectUserFilterdData = createSelector(
  getProductsState,
  (state: SummaryState) => {
    return state.filteredUserData;
  }
);

export const selectBreadCrumbs = createSelector(
  getProductsState,
  (state: SummaryState) => {
    return state.breadCrumbs;
  }
);
export const selectedCustomDatetoDropdown = createSelector(
  getProductsState,
  (state: SummaryState) => {
    return state.selectedCustomDate;
  }
);
export const selectedMonthSummaryDropdown = createSelector(
  getProductsState,
  (state: SummaryState) => {
    return state.selectedMonth;
  }
);