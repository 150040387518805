import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class RolesGuard implements CanActivate {
    constructor(private router: Router) { }

    canActivate(): boolean {
        const tokenData = localStorage.getItem('accessToken');
        if (tokenData) {
            const decodedInfo = jwt_decode(tokenData).permission[0];
            if (decodedInfo === 'CERTUSOHS_ADMIN') {
                return true;
            } else {
                this.router.navigateByUrl('test-summary/facility/summary');
                return false;
            }
        }
    }
}
