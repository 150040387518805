import {
  Component, OnInit, Input,
  Output,
  EventEmitter
} from '@angular/core';
import { SetupTestCardConfigModel } from './setup-test-card-config.model';

@Component({
  selector: 'cer-setup-test-card',
  templateUrl: './setup-test-card.component.html',
  styleUrls: ['./setup-test-card.component.scss']
})
export class SetupTestCardComponent implements OnInit {
  constructor() { }

  @Input() setupTestCardConfigModel: SetupTestCardConfigModel;
  @Input() setupTestList;
  @Output() editSelectedTest = new EventEmitter();

  ngOnInit() {
  }

  editTest(testItem) {
    this.editSelectedTest.emit(testItem);
  }
}