import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';
import { AppState } from 'src/app/app.model';
import { AuthService } from '../core/services/auth.service';
import { UserLogin } from '../core/models/userLogin.model';
import { ROUTE_TEST_SUMMARY, ROUTE_FACILITY, ROUTE_SUMMARY } from '../test-summary/test-summary.config';
import { ROUTE_LOGIN, ROUTE_RESET_PASSWORD } from './login.config';
import { SetSelectedCompany } from '../core/store/facility-store/facility.actions';

@Component({
  selector: 'cer-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public errorMessage: string;
  constructor(
    private router: Router,
    private authService: AuthService,
    private store: Store<AppState>,
    private translate: TranslateService) {
    this.translate.setDefaultLang('en');
  }

  /**
   * Use to create resactive login form
   */
  public ngOnInit() {
    this.loginForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(8)])
    });
    const tokenData = localStorage.getItem('accessToken');
    const decodedInfo = jwt_decode(tokenData);
    const selectedCompanyId: string = decodedInfo.companyId;
    this.store.dispatch(new SetSelectedCompany(selectedCompanyId));
  }

  /**
   * Use to submit the login form
   */
  public onSubmit() {
    const requestBody: UserLogin = {
      email: this.loginForm.get('email').value.trim(),
      password: this.loginForm.get('password').value.trim()
    };
    this.authService.getAccessToken(requestBody).subscribe(response => {
      if (response) {
        localStorage.setItem('accessToken', response.accessToken);
        const tokenData = localStorage.getItem('accessToken');
        if (tokenData) {
          const decodedInfo = jwt_decode(tokenData);
          const facilityId = decodedInfo.facilityId;
          if (decodedInfo.forceResetPassword) {
            this.router.navigateByUrl(`${ROUTE_LOGIN}/${ROUTE_RESET_PASSWORD}`);
          } else {
            this.router.navigateByUrl(`${ROUTE_TEST_SUMMARY}/${ROUTE_FACILITY}/${ROUTE_SUMMARY}`);
          }
        }
      }
    },
      error => {
        this.errorMessage = error.error.message;
        if (this.errorMessage === 'INVALID_CREDENTIALS') {
          this.errorMessage = 'Invalid Credentials';
        }
        if (this.errorMessage === 'INACTIVE_USER_CAN_NOT_LOGIN') {
          this.errorMessage = 'User Is Inactive';
        }
        if (this.errorMessage === 'PARTICIPANT_USER_CAN_NOT_LOGIN') {
          this.errorMessage = 'A User with role PARTICIPANT_USER is not allowed to login';
        }
      });
  }
}