import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { DatePickerDateFormat, DATE_PICKER_DATE_FORMATS } from '../../healpers/date-picker-date-format';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.model';
import { SetCustomSelectedDateToDropdown } from 'src/app/test-summary/store/test-summary.action';
import { CustomDate } from 'src/app/test-summary/store/test-summary.model';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: DatePickerDateFormat },
    { provide: MAT_DATE_FORMATS, useValue: DATE_PICKER_DATE_FORMATS }
  ]
})
export class DateRangePickerComponent implements OnInit {
  public startDateValue: string;
  public endDateValue: string;
  public dateForm: FormGroup;
  public disabled: true;
  public newDate: Date;
  public customPrevDay;
  public customNextDay;
  constructor(private fb: FormBuilder, private datePipe: DatePipe, private store: Store<AppState>) { }
  @Output() customDateDetails = new EventEmitter();
  @Output() cancel = new EventEmitter();
  today = new Date();

  ngOnInit() {
    this.dateForm = this.fb.group(
      {
        fromDate: [''],
        toDate: ['']
      }
    );
  }

  public onCancel() {
    this.cancel.emit();
  }

  public reset() {
    this.dateForm.reset();
  }

  public startPrev(element: HTMLElement) {
    if ((element.getAttribute('formControlName')) === 'fromDate') {
      this.customPrevDay = this.dateForm.controls['fromDate'];
    } else {
      this.customPrevDay = this.dateForm.controls['toDate'];
    }
    this.newDate = new Date(this.customPrevDay.value);
    this.setPrevDate(this.newDate);
  }
  public setPrevDate(newPrevDate) {
    const currentDate = this.datePipe.transform(newPrevDate, 'MM-dd-yyyy');
    const prevDate = new Date(newPrevDate);
    const firstDay = this.datePipe.transform(
      new Date(prevDate.getFullYear(), prevDate.getMonth(), 1), 'MM-dd-yyyy');
    const lastday = this.datePipe.transform(
      new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 0), 'MM-dd-yyyy');
    if (firstDay < currentDate || lastday > currentDate) {
      if (this.newDate.getDate() - 1 < 1) {
      const last = this.datePipe.transform(
        new Date(this.newDate.getFullYear(), this.newDate.getMonth(), 0), 'MM-dd-yyyy');
      const lastDayMonth = last.split('-')[0];
      const lastDay = last.split('-')[1];
      const lastDayYear = last.split('-')[2];
      this.customPrevDay.setValue((lastDayMonth) + '/' +
        (lastDay) + '/' + (lastDayYear));
      } else {
        this.customPrevDay.setValue((this.newDate.getMonth() + 1) + '/' +
        (this.newDate.getDate() - 1) + '/' + (this.newDate.getFullYear()));
      }
    }
  }

  public startNext(element: HTMLElement) {
    if ((element.getAttribute('formControlName')) === 'fromDate') {
      this.customNextDay = this.dateForm.controls['fromDate'];
    } else {
      this.customNextDay = this.dateForm.controls['toDate'];
    }
    this.newDate = new Date(this.customNextDay.value);
    this.setNextDate(this.newDate);
  }
  public setNextDate(newNextDate) {
    const currentNextDate = this.datePipe.transform(newNextDate, 'MM-dd-yyyy');
    const nextDate = new Date(newNextDate);
    const firstNextDay = this.datePipe.transform(
      new Date(nextDate.getFullYear(), nextDate.getMonth(), 1), 'MM-dd-yyyy');
    const lastNextday = this.datePipe.transform(
      new Date(nextDate.getFullYear(), nextDate.getMonth() + 1, 0), 'MM-dd-yyyy');
    if (firstNextDay < currentNextDate || lastNextday > currentNextDate) {
      if (this.newDate.getDate() + 1 > +lastNextday.split('-')[1]) {
        const nextLast = this.datePipe.transform(
          new Date(this.newDate.getFullYear(), this.newDate.getMonth() + 1, 1), 'MM-dd-yyyy');
        const nextLastDayMonth = nextLast.split('-')[0];
        const nextLastDay = nextLast.split('-')[1];
        const nextLastDayYear = nextLast.split('-')[2];
        this.customNextDay.setValue((nextLastDayMonth) + '/' + (nextLastDay) + '/' + (nextLastDayYear));
    } else {
      this.customNextDay.setValue((this.newDate.getMonth() + 1) + '/' +
      (this.newDate.getDate() + 1) + '/' + (this.newDate.getFullYear()));
      }
    }
  }
  /**
   * get the start date from cistom calender when start date selected
   * @param startDateChange capture start date from event
   */
  public startDateChanged(startDateChange) {
    this.startDateValue = this.datePipe.transform(
      startDateChange, 'MM/dd/yyyy'
    );
    this.dateForm.controls['fromDate'].setValue(this.startDateValue);
  }
  /**
   * get the end date from custom calender when end date selected
   * @param endDateChange capture end date from event
   */
  public endDateChanged(endDateChange) {
    this.endDateValue = this.datePipe.transform(
      endDateChange, 'MM/dd/yyyy'
    );
    this.dateForm.controls['toDate'].setValue(this.endDateValue);
  }

  choosedDate(event) {
    const customeDate = {
      startDate: this.startDateValue,
      endDate: this.endDateValue
    };
    this.customDateDetails.emit(customeDate);
    this.store.dispatch(new SetCustomSelectedDateToDropdown(customeDate));
    this.cancel.emit();
  }
}