import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import {
  FacilityActionTypes,
  FetchAllFacility,
  FetchAllFacilitySuccess,
  FetchCompanyDetails,
  FetchCompanyDetailsSuccess,
  FetchAllCompanyNames,
  FetchAllCompanyNamesSuccess
} from './../facility-store/facility.actions';
import { FacilitiesService } from './../../../user-management/facilities/facilities.service';
import { AddWorkOrderService } from 'src/app/workOrder-management/Add-workorder/Add-workorder.service';
import { CompanyDetails, CompanyList } from 'src/app/workOrder-management/Add-workorder/Add-workorder.model';
import { of } from 'rxjs';

@Injectable()
export class CoreEffects {
  constructor(private actions$: Actions,
              private allFacilitiesService: FacilitiesService,
              private addWorkOrderService: AddWorkOrderService
    ) {}

  @Effect()
  public allFacilityData$ = this.actions$.pipe(
    ofType(FacilityActionTypes.FETCH_ALL_FACILITY),
    switchMap((action: FetchAllFacility) => {
      return this.allFacilitiesService.getAllFacility(action.cId, action.fId).pipe(
        map((res: any , error: any) => new FetchAllFacilitySuccess(res)),
        catchError((error: any) => {
          if (error.error.message === 'NO_FACILITIES_FOUND_FOR_COMPANY') {
            return of(new FetchAllFacilitySuccess(error));
          }
        }
        )
      );
    })
  );

  @Effect()
    public fetchCompanydetails$ = this.actions$.pipe(
        ofType(FacilityActionTypes.FETCH_COMPANY_DETAILS),
        switchMap((action: FetchCompanyDetails) =>
            this.addWorkOrderService.getAllCompaniesList()),
        map((res: CompanyDetails[]) => {
            return new FetchCompanyDetailsSuccess(res);
        })
    );

  @Effect()
  public fetchCompanyListName$ = this.actions$.pipe(
    ofType(FacilityActionTypes.FETCH_ALL_COMPANY_NAME),
    switchMap((action: FetchAllCompanyNames) =>
        this.addWorkOrderService.getAllCompaniesName()),
    map((res: CompanyList[]) => {
        return new FetchAllCompanyNamesSuccess(res);
    })
);
}
