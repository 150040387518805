export class SkeletonState {
  skeletonData: any = {
    sideNav: {
        profileImage: false,
        profileName: false
      },
    testSummary: {
        totalTestDonut: false,
        completedTestBarGraph: false
      },
      allFacilityCards: {
        facilityCards: false
      },
      manageUser: {
        displayUserTable: false
      }
  };
}
