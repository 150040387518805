import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.model';
import { map } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AddWorkOrderService {
  constructor(private http: HttpClient, private store: Store<AppState>) { }

  public getAllTest() {
    return this.http.get(`${environment.apiBaseUrl}/diagnostictests`).
      pipe(map(response => {
        return response;
      }));
  }

  public getAllCompanies(companyInput?: string) {
    return this.http.get(`${environment.apiBaseUrl}/companies?freeTextSearch=${companyInput}`).
      pipe(map(response => {
        return response;
      }));
  }

  public getAllCompaniesList() {
    return this.http.get(`${environment.apiBaseUrl}/companies`).
      pipe(map(response => {
        return response;
      }));
  }

  public getAllOnboardedCompanies() {
    return this.http.get(`${environment.apiBaseUrl}/companies?size=-1&companyStatus=Onboarded`).
      pipe(map(response => {
        return response;
      }));
  }

  public getFreeTextOnboardedCompanies(companyInput?: string) {
    return this.http.get(`${environment.apiBaseUrl}/companies?size=-1&companyStatus=Onboarded&freeTextSearch=${companyInput}`).
      pipe(map(response => {
        return response;
      }));
  }

  public getAllCompaniesName() {
    return this.http.get(`${environment.apiBaseUrl}/companies?status=Pending,Onboarded&size=-1`).
      pipe(map(response => {
        return response;
      }));
  }

  public getAllFacilitiesList(companyId: number) {
    return this.http.get(`${environment.apiBaseUrl}/companies/${companyId}/facilities`).
      pipe(map(response => {
        return response;
      }));
  }

  public getAllActiveFacilitiesList(companyId: number) {
    return this.http.get(`${environment.apiBaseUrl}/companies/${
      companyId}/facilities?size=-1&status=Active`).
      pipe(map(response => {
        return response;
      }));
  }

  public getAllFacilities(companyId: number, facilityInput: string) {
    return this.http.get(`${environment.apiBaseUrl}/companies/${companyId}/facilities?freeTextSearch=${facilityInput}`).
      pipe(map(response => {
        return response;
      }));
  }

  public getFreeTextFacilities(companyId: number, facilityInput: string) {
    return this.http.get(`${environment.apiBaseUrl}/companies/${
      companyId}/facilities?size=-1&status=Active&freeTextSearch=${facilityInput}`).
      pipe(map(response => {
        return response;
      }));
  }

  public getSelectedCompanyData(companyId: number) {
    return this.http.get(`${environment.apiBaseUrl}/companies/${companyId}`).
      pipe(map(response => {
        return response;
      }));
  }

  public getSelectedFacilityData(companyId: number, facilityId: number) {
    return this.http.get(`${environment.apiBaseUrl}/companies/${companyId}/facilities/${facilityId}`).
      pipe(map(response => {
        return response;
      }));
  }

  public getTestsPerFacility(companyId: number, facilityId: number) {
    return this.http.get(`${environment.apiBaseUrl}/companies/${
      companyId}/facilities/${facilityId}/tests/available`).
      pipe(map(response => {
        return response;
      }));
  }

  public getDiagnosticTestPerId(diagnosticTestId: number) {
    return this.http.get(`${environment.apiBaseUrl}/diagnostictests/${diagnosticTestId}`).
      pipe(map(response => {
        return response;
      }));
  }
  public updateWorkOrder(companyId: number, facilityId: number, workOrderId: number, requestBody?: any) {
    return this.http.put(`${environment.apiBaseUrl}/workorders/companies/${companyId}/facilities/${
      facilityId}/${workOrderId}`, requestBody).
      pipe(map(response => {
        return response;
      }));
  }

  public createWorkOrder(companyId: number, facilityId: number, createWorkOrderInput: any): Observable<any> {
    return this.http.post<any>(`${environment.apiBaseUrl}/workorders/companies/${
      companyId}/facilities/${facilityId}`, createWorkOrderInput).
      pipe(map(response => {
        return response;
      }));
  }
}