import { Component, Input, Output, EventEmitter } from '@angular/core';

/**
 * Enum for button color
 */
export enum ButtonKind {
  Primary = 'primary',
  Accent = 'accent',
  Warn = 'warn',
}

/**
 * Enum for button types
 */
export enum ButtonType {
  Basic = 'mat-button',
  Raised = 'mat-raised-button',
  Stroked = 'mat-stroked-button',
  Fab = 'mat-fab'
}

/**
 * Enum for button size
 */
export enum ButtonSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

@Component({
  selector: 'cer-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})

export class ButtonComponent {

  /**
   * Use to set button title
   */
  @Input()
  public title: string;

  /**
   * Use to emit event from button click
   */
  @Output()
  public buttonClicked: EventEmitter<Date> = new EventEmitter<Date>();

  /**
   * The color scheme of the button.
   */
  @Input()
  public kind: ButtonKind = ButtonKind.Primary;

  /**
   * The size of the button.
   */
  @Input()
  public size: ButtonSize = ButtonSize.Medium;

  /**
   * The type of the button.
   */
  @Input()
  public btnType: ButtonType = ButtonType.Raised;

  /**
   * To disable the button
   */
  @Input()
  public disabled: boolean;


  /**
   * Use to emit value on click
   */
  public onClick() {
    this.buttonClicked.emit();
  }
}