import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ResetPassword, ResetPasswordResponse } from './reset-password.model';

@Injectable({
    providedIn: 'root'
})
export class ResetPasswordService {

    constructor(private http: HttpClient) { }

    public resetPassword(userInput: ResetPassword) {
        return this.http.post<ResetPasswordResponse>(`${environment.apiBaseUrl}/auth/password`, userInput).
            pipe(map(response => {
                return response;
            }));
    }
}
