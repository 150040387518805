import { createSelector, createFeatureSelector } from '@ngrx/store';
import { WorkOrderState } from './workOrder-management.model';
export const getProductsState = createFeatureSelector('workOrderReducer');

export const selectCreateWorkOrder = createSelector(
  getProductsState,
  (state: WorkOrderState) => {
    return state.createWorkOrder;
  }
);

export const getSelectedWorkOrderDetais = createSelector(
  getProductsState,
  (state: WorkOrderState) => {
    return state.selectedWorkOrderData;
  }
);

export const updateWorkoOderData = createSelector(
  getProductsState,
  (state: WorkOrderState) => {
    return state.updateWorkOrder;
  });

export const getAllWorkOrderDetailData = createSelector(
  getProductsState,
  (state: WorkOrderState) => {
    return state.allWorkOrderDetailData;
  }
);

export const getSelectedWorkOrderInfo = createSelector(
  getProductsState,
  (state: WorkOrderState) => {
    return state.workOrderDetails;
  }
);

export const selectWorkorderDonutDataError = createSelector(
  getProductsState,
  (state: WorkOrderState) => {
    return state.workOrderDonutDataError;
  }
);