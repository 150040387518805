import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FacilityState } from '../../models/facility.model';

export const getFacilityState = createFeatureSelector('facilityReducer');

export const selectUserFacilities = createSelector(
    getFacilityState,
    (state: FacilityState) => {
        return state.userFacilities;
    }
);

export const selectSelectedFacility = createSelector(
    getFacilityState,
    (state: FacilityState) => {
        return state.selectedFacility;
    }
);
export const selectSelectedCompany = createSelector(
    getFacilityState,
    (state: FacilityState) => {
        return state.selectedCompany;
    }
);
export const getCompanyDetail = createSelector(
    getFacilityState,
    (state: FacilityState) => {
        return state.companyDetail;
    }
);
export const setCompanyDropdownFlag = createSelector(
    getFacilityState,
    (state: FacilityState) => {
        return state.setCompanyDropDownFlag;
    }
);
export const setCompanyFacilityId = createSelector(
    getFacilityState,
    (state: FacilityState) => {
        return state.setSelectedCompanyFacility;
    }
);
export const setFacilityFlag = createSelector(
    getFacilityState,
    (state: FacilityState) => {
        return state.facilityDropdownFlag;
    }
);
export const selectAllCompanyNames = createSelector(
    getFacilityState,
    (state: FacilityState) => {
        return state.allCompanyNames;
    }
);

export const setCurrentCompanyData = createSelector(
    getFacilityState,
    (state: FacilityState) => {
      return state.currentCompanyDataToDropdown;
    }
  );