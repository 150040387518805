import { Component, OnInit } from '@angular/core';
import {
  ROUTE_TEST_SUMMARY,
  ROUTE_SUMMARY,
  ROUTE_FACILITY
} from 'src/app/test-summary/test-summary.config';
import {
  ROUTE_USER_MANAGEMENT,
  ROUTE_FACILITIES
} from 'src/app/user-management/user-management.config';
import * as jwt_decode from 'jwt-decode';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.model';
import { TranslateService } from '@ngx-translate/core';
import { ManageUsersService } from 'src/app/user-management/manage-users/manage-users.service';
import { ClearFacility } from '../../store/facility-store/facility.actions';
import {
  ROUTE_WORKORDER_MANAGEMENT,
  ROUTE_WORKORDER_DETAILS
} from 'src/app/workOrder-management/workOrder-management.config';
import { ROUTE_COMPANY_MANAGEMENT, ROUTE_COMPANY_DETAILS } from 'src/app/company-management/company-management.config';
import { filter } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cer-sidenav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private store: Store<AppState>,
    private manageUsersService: ManageUsersService,
    private sanitizer: DomSanitizer
  ) { }

  public dispalyName: string;
  public position: string;
  public facilityId: number;
  public userId: string;
  public sideNav: any;
  public selectedRouteIndex = 0;
  public showUserIcon: boolean;
  public logoBase64: string;
  public imagePath;
  public showDefaultLogo: boolean;

  /**
   * Used to get the decoded Info from local storage
   */
  async ngOnInit() {
    this.showUserIcon = false;
    this.showDefaultLogo = false;
    await new Promise(res => {
      this.translate.get('SIDE_NAV').subscribe(translations => {
        res();
      });
    });
    const tokenData = localStorage.getItem('accessToken');
    if (tokenData) {
      const decodedInfo = jwt_decode(tokenData);
      this.dispalyName = `${decodedInfo.firstName} ${decodedInfo.lastName}`;
      this.facilityId = decodedInfo.facilityId;
      this.userId = decodedInfo.id;
      if (decodedInfo['companyId'] === -1) {
        this.showUserIcon = true;
      } else {
        this.showUserIcon = false;
        this.manageUsersService.getCompanyLogo(decodedInfo['companyId']).subscribe(response => {
          if (response !== null) {
            if (response['companyLogo'] !== null) {
              this.showDefaultLogo = true;
              this.logoBase64 = response['companyLogo'];
              this.imagePath = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
                + this.logoBase64);
            } else {
              this.showDefaultLogo = false;
            }
          } else {
            this.showDefaultLogo = false;
          }
        });
      }
    }

    this.manageUsersService
      .getUserDetailsById(this.userId)
      .subscribe(response => {
        this.position = response['position'];
      });

    this.sideNav = [
      {
        route: ['/', ROUTE_TEST_SUMMARY, ROUTE_FACILITY, ROUTE_SUMMARY],
        label: this.translate.instant('SIDE_NAV.TEST_SUMMARY'),
        indexKey: 0,
        parentRouteKey: ROUTE_TEST_SUMMARY,
        iconClass: 'sidebar-main-nav__icon icon-test-summery'
      },
      {
        route: ['/', ROUTE_USER_MANAGEMENT, ROUTE_FACILITY, ROUTE_FACILITIES],
        label: this.translate.instant('SIDE_NAV.USER_MANAGEMENT'),
        indexKey: 1,
        parentRouteKey: ROUTE_USER_MANAGEMENT,
        iconClass: 'sidebar-main-nav__icon icon-users'
      },
      {
        route: ['/', ROUTE_COMPANY_MANAGEMENT, ROUTE_COMPANY_DETAILS],
        label: this.translate.instant('SIDE_NAV.COMPANY_MANAGEMENT'),
        indexKey: 2,
        parentRouteKey: ROUTE_COMPANY_MANAGEMENT,
        iconClass: 'sidebar-main-nav__icon icon-briefcase'
      },
      {
        route: ['/', ROUTE_WORKORDER_MANAGEMENT, ROUTE_WORKORDER_DETAILS],
        label: this.translate.instant('SIDE_NAV.WORK_ORDER'),
        indexKey: 3,
        parentRouteKey: ROUTE_WORKORDER_MANAGEMENT,
        iconClass: 'sidebar-main-nav__icon icon-setup-test'
      },
      {
        route: `Reports`,
        label: this.translate.instant('SIDE_NAV.REPORTS'),
        indexKey: 4,
        iconClass: 'sidebar-main-nav__icon icon-reports'
      },
    ];
    this.setSelectedRouteIndex();
    if (tokenData) {
      const decodedPermission = jwt_decode(tokenData).permission[0];
      if (decodedPermission !== 'CERTUSOHS_ADMIN') {
        this.sideNav.splice(2, 2);
        // this.sideNav.splice(3, 1);
      }
      // if (decodedPermission === 'CERTUSOHS_ADMIN') {
      //   this.disableTestSummaryKey = 0;
      //   this.disableUserManagementKey = 1;
      // }
    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  setSelectedRouteIndex() {
    const urlChild = this.router.url.split('/')[1];
    this.router.events.subscribe((res: NavigationEvent) => {
      if (res instanceof NavigationEnd) {
        const urlFirstChild = res.url.split('/')[1];
        const matchedRedirectedRoute = this.sideNav.find(nav => {
          return nav.parentRouteKey === urlFirstChild;
        });
        this.selectedRouteIndex = matchedRedirectedRoute.indexKey;
      }
    });
    const matchedOnLoadRoute = this.sideNav.find(nav => {
      return nav.parentRouteKey === urlChild;
    });
    const selectedOnLoadRoute = matchedOnLoadRoute.indexKey;
    this.selectedRouteIndex = this.selectedRouteIndex ? this.selectedRouteIndex : selectedOnLoadRoute;
  }

  /**
   * Used to logout the application
   */
  logout() {
    localStorage.clear();
    this.store.dispatch(new ClearFacility());
    this.router.navigateByUrl('/login');
  }

  /**
   * Used to assign selected tab index to side nav index
   * @param index Used to pass selected tab index
   */
  selectTab(index) {
    this.selectedRouteIndex = index;
  }
}
