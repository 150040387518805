import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Activity } from '../../shared.model';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from 'src/app/app.model';
import { Store } from '@ngrx/store';
import { selectedCustomDatetoDropdown, selectedMonthSummaryDropdown } from 'src/app/test-summary/store/test-summary.selectors';
@Component({
  selector: 'cer-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropDownComponent implements OnInit {
  public selectedMonth: string;
  public DateRange: Activity[];
  public selectedStartDate: string;
  public selectedEndDate: string;
  @Output() selectedDate = new EventEmitter();
  @Input() disableDropdown: boolean;
constructor(private translate: TranslateService, private store: Store<AppState>) { }

​public selectedCustomDatetoDropdown$ = this.store.select(selectedCustomDatetoDropdown);
public selectedMonthSummaryDropdown$ = this.store.select(selectedMonthSummaryDropdown);
async ngOnInit() {
  await new Promise(res => {
    this.translate.get('DROPDOWN').subscribe(translations => {
      res();
    });
  });
  this.DateRange = [
    {
      value: this.translate.instant('DROPDOWN.MONTH'),
      viewValue: this.translate.instant('DROPDOWN.LAST_MONTH')
    },
    {
      value: this.translate.instant('DROPDOWN.QUARTER'),
      viewValue: this.translate.instant('DROPDOWN.LAST_QUARTER_MONTH')
    },
    {
      value: this.translate.instant('DROPDOWN.6MONTH'),
      viewValue: this.translate.instant('DROPDOWN.LAST_6_MONTH')
    },
    {
      value: this.translate.instant('DROPDOWN.YEAR'),
      viewValue: this.translate.instant('DROPDOWN.LAST_YEAR')
    },
    {
      value: this.translate.instant('DROPDOWN.CUSTOM'),
      viewValue: this.translate.instant('DROPDOWN.CUSTOM')
    },
  ];
  if (!this.selectedMonth) {
    this.selectedMonth = this.DateRange[0].value;
  }
  this.selectedMonthSummaryDropdown$.subscribe(res => {
    if (res) {
      this.selectedMonth = res;
    }
  });
  this.selectedCustomDatetoDropdown$.subscribe(res => {
    if (res) {
      this.selectedStartDate = res.startDate;
      this.selectedEndDate = res.endDate;
    }
  });
}

  onDateSelected(event) {
    this.selectedStartDate = '';
    this.selectedEndDate = '';
    if (event.isUserInput === true) {
      this.selectedDate.emit(event.source.value);
    }
  }
}