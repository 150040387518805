import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'cer-save-exit-pop-up',
  templateUrl: './save-exit-pop-up.component.html',
  styleUrls: ['./save-exit-pop-up.component.scss'],
})
export class SaveExitPopUpComponent {
    @Input() public showModal: boolean = null;
    @Output() public closeModal: EventEmitter<Event> = new EventEmitter<Event>();
    @Output() public okClick: EventEmitter<Event> = new EventEmitter<Event>();
  constructor() { }
}