import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(): boolean {
        const tokenData = localStorage.getItem('accessToken');
        if (!tokenData) {
            this.router.navigateByUrl('login');
            return false;
        }
        return true;
    }
}
