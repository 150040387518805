import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'cer-popUp',
  templateUrl: './pop-up.component.html',
  styleUrls: ['./pop-up.component.scss'],
})
export class PopUpComponent {
    @Input() public showModal: boolean = null;
    @Output() public closeModal: EventEmitter<Event> = new EventEmitter<Event>();
    @Input() public title: string;
  constructor() { }
}