import { NativeDateAdapter } from '@angular/material';
export class DatePickerDateFormat extends NativeDateAdapter {
    parse(value: any): Date | null {
        if (typeof value === 'string' && value.indexOf('/') > -1) {
            const slash = value.split('/');
            const year = Number(slash[2]);
            const month = Number(slash[1]) - 1;
            const date = Number(slash[0]);
            return new Date(year, month, date);
        }
        const timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    }
    format(date: Date, displayFormat: string): string {
        const century21st = 2000;
        const century20th = 1900;
        if (displayFormat === 'input') {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            // return year + '/' + this.addZero(month) + '/' + this.addZero(day);
            return this.addZero(month) + '/' + this.addZero(day) + '/' + year;
        } else if (displayFormat === 'inputMonth') {
            const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            return monthNames[month - 1] + ' ' + year;
        } else {
            return date.toDateString();
        }
    }
    private addZero(num: number) {
        return ('00' + num).slice(-2);
    }
}
export const DATE_PICKER_DATE_FORMATS = {
    parse: {
        dateInput: { month: 'short', year: 'numeric', day: 'numeric' }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: 'inputMonth',
        dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'long' }
    }
};
