import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

export enum messageType {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFORMATION = 'INFORMATION',
  WARNING = 'WARNING'
}

@Component({
  selector: 'sa-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnChanges {
  constructor(private snackBar: MatSnackBar) { }
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  setAutoHide = true;
  autoHide = 10000;

  @Input()
  public toasterSummary;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.toasterSummary !== undefined) {
      this.genrateAlertMassage(changes.toasterSummary.currentValue);
    }
  }

  public genrateAlertMassage(toasterSummary) {
    if (toasterSummary) {
      switch (toasterSummary.type) {
        case messageType.SUCCESS:
          this.openSuccess(this.toasterSummary.description, 'x');
          break;
        case messageType.INFORMATION:
          this.openInfo(this.toasterSummary.description, 'x');
          break;
        case messageType.ERROR:
          this.openError(this.toasterSummary.description, 'x');
          break;
          case messageType.WARNING:
            this.openWarning(this.toasterSummary.description, 'x');
            break;
      }
    }
  }

  public openSuccess(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    this.snackBar.open(message, action, config);
  }

  public openWarning(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['warning'];
    this.snackBar.open(message, action, config);
  }

  public openInfo(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['info'];
    this.snackBar.open(message, action, config);
  }

  public openError(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.verticalPosition = this.verticalPosition;
    config.horizontalPosition = this.horizontalPosition;
    config.duration = this.setAutoHide ? this.autoHide : 0;
    config.panelClass = ['error'];
    this.snackBar.open(message, action, config);
  }
}
