import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InteriorPageComponent } from './core/components/interior-page/interior-page.component';
import { AuthGuard } from './core/guards/auth.guard';
import * as jwt_decode from 'jwt-decode';
import { ROUTE_TEST_SUMMARY, ROUTE_SUMMARY, ROUTE_FACILITY } from './test-summary/test-summary.config';
import { ROUTE_LOGIN } from './login/login.config';
import { ROUTE_USER_MANAGEMENT } from './user-management/user-management.config';
import { ROUTE_SETUP_TEST } from './setup-test/setup-test.config';
import { ROUTE_WORKORDER_MANAGEMENT, ROUTE_WORKORDER_DETAILS } from './workOrder-management/workOrder-management.config';
import { RolesGuard } from './core/guards/roles.guard';
import { ROUTE_COMPANY_MANAGEMENT } from './company-management/company-management.config';
import { RoutePermissionGuard } from './core/guards/route-permission.guard';

let facilityId = '-1';
const tokenData = localStorage.getItem('accessToken');
if (tokenData) {
  const decodedInfo = jwt_decode(tokenData);
  // if (decodedInfo.permission[0] !== 'CERTUSOHS_ADMIN') {
  //   this.defaultRoute = `${ROUTE_WORKORDER_MANAGEMENT}/${ROUTE_WORKORDER_DETAILS}`;
  // } else {
  //   this.defaultRoute = `${ROUTE_TEST_SUMMARY}/${ROUTE_FACILITY}/${ROUTE_SUMMARY}`;
  // }
  facilityId = decodedInfo.facilityId;
}

export const defaultRoute = `${ROUTE_TEST_SUMMARY}/${ROUTE_FACILITY}/${ROUTE_SUMMARY}`;
// export const defaultRoute = `${ROUTE_WORKORDER_MANAGEMENT}/${ROUTE_WORKORDER_DETAILS}`;

export const routes: Routes = [
  {
    path: ROUTE_LOGIN,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: '',
    component: InteriorPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: defaultRoute,
        canActivate: [AuthGuard],
        pathMatch: 'full'
      },
      {
        path: ROUTE_TEST_SUMMARY,
        canActivate: [AuthGuard],
        loadChildren: () => import('./test-summary/test-summary.module').then(m => m.TestSummaryModule)
      },
      {
        path: ROUTE_USER_MANAGEMENT,
        loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: ROUTE_SETUP_TEST,
        loadChildren: () => import('./setup-test/setup-test.module').then(m => m.SetupTestModule)
      },
      {
        path: ROUTE_WORKORDER_MANAGEMENT,
        canActivate: [RolesGuard],
        loadChildren: () => import('./workOrder-management/workOrder.module').then(m => m.WorkOrderManagementModule)
      },
      {
        path: ROUTE_COMPANY_MANAGEMENT,
        canActivate: [RolesGuard],
        loadChildren: () => import('./company-management/company-management.module').then(m => m.CompanyManagementModule)
      },
      {
        path: '**',
        redirectTo: ROUTE_LOGIN,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
