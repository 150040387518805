import { Injectable } from '@angular/core';
import { UserLogin, LoginResponse } from '../../core/models/userLogin.model';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  /**
   * Use to get access token after login
   * @param userInput use to pass input paramater
   */
  public getAccessToken(userInput: UserLogin) {
    return this.http.post<LoginResponse>(`${environment.apiBaseUrl}/auth/login`, userInput).
      pipe(map(response => {
        return response;
      }));
  }
}
