import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactive {
    confirm(): boolean;
}

@Injectable()
export class RoutePermissionGuard implements CanDeactivate<CanComponentDeactive> {
    canDeactivate(
        component: CanComponentDeactive,
    ): Observable<boolean> | Promise<boolean> | boolean {
        return component.confirm();
    }

}
