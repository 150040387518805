
import { LoginResponseModel, DemoResponseModel } from '../core/models/activity.model';
import { ActivityActionType, ActivityActions } from './auth.action';

// export function loginReducer(state: LoginResponseModel[] = [],

export function loginReducer(state: DemoResponseModel[] = [], action: ActivityActions) {
  switch (action.type) {
    case ActivityActionType.ACTION_LOGIN:
      //   return {...state, login :action.payload};
      return [...state, action.payload];
    default:
      return state;
  }
}