import { ButtonComponent } from './components/button/button.component';
import { DropDownComponent } from './components/dropdown/dropdown.component';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumb.component';
import { DonutGraphComponent } from './components/donut-graph/donut-graph.component';
import { TableComponent } from './components/table/table.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { SetupTestCardComponent } from './components/setup-test-card/setup-test-card.component';
import { EditTestCardComponent } from './components/edit-test-card/edit-test-card.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { ModalComponent } from './components/modal/modal.component';
import { FirstLetterCapitalDirective } from './directives/first-letter-capital.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { PhoneMaskDirective } from './directives/phone-number-masking-directive';
import { SaveExitPopUpComponent } from './components/save-exit-pop-up/save-exit-pop-up.component';
import { FocusDirective } from './directives/focus.directive';
import { SpecialCharacterDirective } from './directives/special-character-not-allowed.directive';
import { OnlyStringDirective } from './directives/only-string.directive';
import { OnlyNumericDirective } from './directives/only-numeric.directive';

const SharedComponents = [
  ButtonComponent,
  DropDownComponent,
  DynamicTableComponent,
  AlertMessageComponent,
  BreadcrumbsComponent,
  DonutGraphComponent,
  TableComponent,
  DatePickerComponent,
  DateRangePickerComponent,
  ModalComponent,
  InputFieldComponent,
  SetupTestCardComponent,
  EditTestCardComponent,
  FirstLetterCapitalDirective,
  FocusDirective,
  ClickOutsideDirective,
  PhoneMaskDirective,
  SpecialCharacterDirective,
  OnlyStringDirective,
  OnlyNumericDirective,
  PopUpComponent,
  SaveExitPopUpComponent
];
export { SharedComponents };
